import { hasIn, isEmpty, isFunction, isObject } from 'lodash'

import { SWISS_BOUNDING_BOX } from '@/components/MedicalSearch/config/constants'

export const createBoundingBox = values => {
  const northEast = values.getNorthEast()
  const southWest = values.getSouthWest()

  return {
    min: { lat: southWest.lat(), lng: southWest.lng() },
    max: { lat: northEast.lat(), lng: northEast.lng() },
  }
}

export const createGoogleBounds = options => {
  return new google.maps.LatLngBounds(options.min, options.max)
}

export const createPosition = position => {
  if (isObject(position)) {
    let lat = position.latitude || position.lat
    let lng = position.longitude || position.lng

    lat = isFunction(lat) ? lat() : parseFloat(lat)
    lng = isFunction(lng) ? lng() : parseFloat(lng)
    if (!Number.isNaN(lat) && !Number.isNaN(lng)) {
      return { lat, lng }
    }
  }
}

export const createSwissBoundingBox = () => {
  return createGoogleBounds(SWISS_BOUNDING_BOX)
}

export const insideSwissBoundingBox = values => {
  let valid = false
  if (isPosition(values)) {
    const { lat, lng } = createPosition(values)
    valid =
      lng < SWISS_BOUNDING_BOX.max.lng &&
      lng > SWISS_BOUNDING_BOX.min.lng &&
      lat < SWISS_BOUNDING_BOX.max.lat &&
      lat > SWISS_BOUNDING_BOX.min.lat
  } else if (values) {
    const bounds = createBoundingBox(values)
    valid =
      bounds.max.lng < SWISS_BOUNDING_BOX.max.lng &&
      bounds.min.lng > SWISS_BOUNDING_BOX.min.lng &&
      bounds.max.lat < SWISS_BOUNDING_BOX.max.lat &&
      bounds.min.lat > SWISS_BOUNDING_BOX.min.lat
  }
  return valid
}

export const isBoundingBox = bounds => {
  const valid = bounds?.max?.lat && bounds?.max?.lng && bounds?.min?.lat && bounds?.min?.lng

  return !valid && !isEmpty(bounds) ? isGoogleBoundingBox(bounds) : valid
}

export const isGoogleBoundingBox = bounds => {
  const valid = bounds.getNorthEast && bounds.getSouthWest

  if (!isEmpty(bounds) && !valid && import.meta.env.DEV) {
    /* eslint-disable no-console */
    console.error('Bounding box is not googleMaps conform!', bounds, isEmpty(bounds))
    throw new Error('Bounding box is not googleMaps conform!')
  }

  return valid
}

const isPosition = position => {
  return (
    isObject(position) &&
    (hasIn(position, 'latitude') || hasIn(position, 'lat')) &&
    (hasIn(position, 'longitude') || hasIn(position, 'lng'))
  )
}

export const isWithinSwissBounds = bounds => {
  return isBoundingBox(bounds) && insideSwissBoundingBox(bounds)
}
