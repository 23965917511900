import { EVENT_CONFIRMATION_MODAL_OPEN } from '@/config/events'
import { events$ } from '@/services'

/**
 *  confirm emits a modal-confirmation event and returns true if
 *  the users confirms and false if the user rejects
 *  @param {object} message
 *  @param {string} message.title
 *  @param {string} message.label
 *  @param {string} message.severity
 *  @param {object} buttons
 *  @param {string} buttons.cancelText
 *  @param {string} buttons.confirmText
 *  @returns {Promise<boolean>}
 */
export async function confirm(message, buttons) {
  try {
    await new Promise((resolve, reject) => {
      events$.emit(EVENT_CONFIRMATION_MODAL_OPEN, {
        message,
        buttons,
        callbacks: { resolve, reject },
      })
    })
    return true
  } catch {
    return false
  }
}
