import { getNextRoute } from '@/helpers/Route'

import basketStore from '@/store/basket'

import { ROUTE_INDEX, ROUTE_NAME } from '@/config/constants'

export default [
  {
    beforeEnter: (_to, _from, next) => {
      if (!basketStore.basket.signature || !basketStore.basket.signature.signatureRequired) {
        next({ name: getNextRoute(basketStore.basket) })
      } else {
        next()
      }
    },
    name: ROUTE_NAME.SIGNATURE,
    path: '/digital-signature',
    children: [
      {
        component: () => import('@/modules/Configurator/screens/DigitalSignature/ScreenEntry'),
        meta: {
          headerDisabled: true,
          navigation: false,
          index: ROUTE_INDEX.SIGNATURE_ENTRY,
        },
        name: ROUTE_NAME.SIGNATURE_ENTRY,
        path: '/digital-signature/entry',
      },
      {
        component: () => import('@/modules/Configurator/screens/DigitalSignature/ScreenVerification'),
        meta: {
          headerDisabled: true,
          navigation: false,
          index: ROUTE_INDEX.SIGNATURE_VERIFICATION,
        },
        name: ROUTE_NAME.SIGNATURE_VERIFICATION,
        path: '/digital-signature/verification',
      },
    ],
  },
]
