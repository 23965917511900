import { isEmpty } from 'lodash'

export const MED_STATE = {
  ALL: 'ACCEPTS_ALL',
  NOBODY: 'ACCEPTS_NOBODY',
  CHILDREN: 'ACCEPTS_CHILDREN',
  FAMILYMEMBERS: 'ACCEPTS_FAMILYMEMBERS',
  FAMILY_DOCTOR: 'ACCORDING_FAMILY_DOCTOR',
  NOT_ELIGIBLE: 'SPECIALIST_NOT_ELIGIBLE',
  UNKNOWN: 'STATUS_UNKNOWN',
}

const currentFilterFunctions = filters => {
  if (isEmpty(filters)) return []

  return filters.map(filter => {
    switch (filter.name.toLowerCase()) {
      case 'name': {
        const fullName = entry => `${entry.firstName} ${entry.lastName}`.toLowerCase()
        return entry => fullName(entry).includes(filter.value.toLowerCase())
      }
      case 'patients':
        return entry => entry.medicalOfficeStatus === MED_STATE.ALL
      default:
        console.error('Undefined filter: ', filter)
        return
    }
  })
}

export const filterOffices = (offices, filters) => {
  if (isEmpty(offices)) return []
  if (isEmpty(filters)) return offices

  const currentFilters = currentFilterFunctions(filters)

  return offices.filter(office => {
    for (let i = 0; i < currentFilters.length; i++) {
      if (!currentFilters[i](office)) {
        return false
      }
    }
    return true
  })
}
