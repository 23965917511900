import basketStore from '@/store/basket'

import { getNextRoute } from '@/helpers/Route'
import { ROUTE_NAME } from '@/config/constants.js'

export default [
  {
    path: '/quiz',
    meta: {
      headerDisabled: true,
      navigation: false,
    },
    children: [
      {
        path: '',
        name: ROUTE_NAME.QUIZ,
        component: () => import('@/modules/Configurator/screens/Quiz/ScreenGame.vue'),
      },
      {
        beforeEnter: (_to, from) => {
          if (from.name !== ROUTE_NAME.QUIZ) {
            return {
              name: getNextRoute(basketStore.basket),
            }
          }
        },
        path: 'form',
        name: ROUTE_NAME.QUIZ_FORM,
        component: () => import('@/modules/Configurator/screens/Quiz/ScreenForm.vue'),
      },
      {
        beforeEnter: (_to, from) => {
          if (from.name !== ROUTE_NAME.QUIZ_FORM) {
            return {
              name: getNextRoute(basketStore.basket),
            }
          }
        },
        path: 'success',
        name: ROUTE_NAME.QUIZ_SUCCESS,
        component: () => import('@/modules/Configurator/screens/Quiz/ScreenSuccess.vue'),
      },
    ],
  },
]
