<template>
  <div
    ref="tanRef"
    class="tan"
  >
    <div
      class="tan__info body-2-expanded"
      v-text="descriptionText"
    />

    <basic-code-input
      v-model="model"
      name="tan"
      :v="v"
    />

    <div class="tan__help">
      <basic-text-button
        :disabled="isResendDisabled"
        :label="$t('screen.reentry.resend')"
        @click="resend"
      >
        <template #trailing>
          <the-icon
            art="solid"
            name="arrow-right"
          />
        </template>
      </basic-text-button>
      <basic-text-button
        v-if="contactInfo && !showContactInfoMessage"
        :label="$t('signature.verification.code.notReceived')"
        @click="showContactInfo"
      >
        <template #trailing>
          <the-icon
            art="regular"
            name="circle-info"
          />
        </template>
      </basic-text-button>
    </div>

    <basic-message
      v-if="showContactInfoMessage"
      class="tan__contactInfo-message"
      :closeable="false"
      :severity="NOTIFICATION.INFO"
      :title-label="$t('signature.verification.code.notReceived')"
    >
      <missing-tan-modal :contact-info="contactInfo" />
    </basic-message>

    <div
      v-if="$slots.actions"
      class="tan__actions"
    >
      <slot name="actions" />
    </div>

    <div
      v-if="showPolicy"
      class="tan__policy"
    >
      <div
        class="title-3"
        v-text="$t('application.privacyPolicy.title')"
      />
      <div
        class="body-3"
        v-html="$t('application.privacyPolicy.text')"
      />
    </div>

    <basic-modal
      id="missingTanModal"
      ref="missingTanModalRef"
      size="midi"
      :modal-title="$t('signature.verification.code.notReceived')"
    >
      <missing-tan-modal :contact-info="contactInfo" />
    </basic-modal>
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue'

import { loading$ } from '@/services'

import basketAPI from '@/api/basket'
import mobileNumberVerificationAPI from '@/api/mobileNumberVerification'
import useContact from '@/hooks/useContact'
import useI18n from '@/hooks/useI18n'
import useSnackbar from '@/hooks/useSnackbar'

import { BasicCodeInput, BasicMessage, BasicModal, BasicTextButton } from '@/components/Basic'
import MissingTanModal from '@/components/Authentication/MissingTanModal'
import { NOTIFICATION } from '@/config/constants'

// HOOKS
const { contactInfo } = useContact()
const { t } = useI18n()
const { addNotification } = useSnackbar()

// INIT
const model = defineModel({ type: String })
const emit = defineEmits(['resend-tan'])

const props = defineProps({
  basketId: {
    type: String,
    default: '',
  },

  hasCustomTanResendFunction: {
    type: Boolean,
    default: false,
  },

  mobile: {
    type: String,
    default: '',
  },

  showPolicy: {
    type: Boolean,
    default: true,
  },

  token: {
    type: String,
    default: '',
  },

  v: {
    type: Object,
    required: true,
  },
})

// DATA
const isResendDisabled = ref(true)
const missingTanModalRef = ref(null)
const showContactInfoMessage = ref(false)
const tanRef = ref(null)

// COMPUTED
const descriptionText = computed(() => {
  return props.mobile ? t('screen.reentry.tanHelptext', { mobile: props.mobile }) : t('screen.auth.confirm.description')
})

// METHODS
async function resend() {
  isResendDisabled.value = true

  if (props.hasCustomTanResendFunction) {
    emit('resend-tan')
    window.setTimeout(() => (isResendDisabled.value = false), 60 * 2 * 1000)
    return
  }

  try {
    loading$.start()

    if (!props.token && !props.basketId) {
      throw Error('Missing either reentryToken or basketId')
    }

    if (props.token) {
      await basketAPI.sendSMSTan(props.token)
    } else {
      await mobileNumberVerificationAPI.sendSMSTanOnLogin({
        basketId: props.basketId,
        mobileNumber: props.mobile,
      })
    }

    addNotification({
      text: t('screen.auth.sent'),
    })

    window.setTimeout(() => (isResendDisabled.value = false), 60 * 2 * 1000)

    loading$.end()
  } catch {
    loading$.failed()
  }
}

function showContactInfo() {
  const isWithinModal = tanRef.value.closest('.dvp-modal')
  isWithinModal ? (showContactInfoMessage.value = true) : missingTanModalRef.value.open()
}

// LIFECYCLE HOOK
onMounted(() => {
  window.setTimeout(() => (isResendDisabled.value = false), 60 * 2 * 1000)
})
</script>

<style scoped>
.tan__info {
  text-align: center;
  margin: 0 0 var(--dotcom-responsive-spacing-res-xl);
}

.tan__help {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--fixed-spacing-fix-06) var(--dotcom-responsive-spacing-res-l);
}

.tan__contactInfo-message {
  margin: var(--dotcom-responsive-spacing-res-m) 0;
}

.tan__actions {
  display: flex;
  flex-direction: column-reverse;
  row-gap: var(--fixed-spacing-fix-02);
  margin: var(--dotcom-responsive-spacing-res-m) 0;
}

.tan__policy {
  display: flex;
  flex-direction: column;
  gap: var(--fixed-spacing-fix-02);
  padding-bottom: var(--dotcom-responsive-spacing-res-xl);

  &:deep(a) {
    color: var(--Interaction-States-enabled-default);
    border-bottom: 1px solid var(--Interaction-States-enabled-default);
    font-weight: bold;
    cursor: pointer;
    transition-property: color, border-color;
    transition-duration: 150ms;
    transition-timing-function: ease-in;

    &:focus-visible {
      outline: 2px solid var(--on-surface);
      outline-offset: var(--fixed-spacing-fix-01);
      border-radius: var(--fixed-border-radius-fix-02);
    }

    &:hover {
      color: var(--Interaction-States-hovered-default-a);
      border-color: var(--Interaction-States-hovered-default-a);
    }
  }
}

@media (--v-medium) {
  .tan__actions {
    flex-direction: row;
    justify-content: flex-end;
    column-gap: var(--fixed-spacing-fix-06);
  }
}
</style>
