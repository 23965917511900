import { cloneDeep } from 'lodash'

import { CHANNEL, ROUTE_INDEX, ROUTE_NAME } from '@/config/constants'

import { isRequired } from '@/utils/CustomErrors'

import basketStore from '@/store/basket'
import useBasket from '@/hooks/useBasket'
import usePerson from '@/hooks/usePerson'
import useStatus from '@/hooks/useStatus'

import { SortUtil } from '@/utils/Sorting'

const { basketHasProducts, isPersonalDataValid } = useStatus()
const { isStaticBasket } = useBasket()
const { filterPersonsToValidate } = usePerson()

export function getRoutes(route, basket) {
  const { routeStates } = useStatus()
  const states = routeStates.value

  const routes = [
    getPersonRoute(route, { basket }, states),
    getOfferRoute(route, { basket }, states),
    getBasketRoute(route, { basket }, states),
    getOnboardingRoute(route, states),
    getApplicationRoute(route, { basket }, states),
    getExistingCustomerRoute(route, { basket }, states),
    getPersonalDataRoute(route, { basket }, states),
    getAdminDataRoute(route, { basket }, states),
    getHealthquestionRoute(route, { basket }, states),
    getSubmitRoute(route, { basket }, states),
    getAdvisoryProtocolRoute(route),
    getSignatureEntryRoute(route, { basket }),
    getSignatureVerificationRoute(route, { basket }),
    getNextStepsRoute(route),
  ]

  return routes.sort(SortUtil.sortByParam('index'))
}

export function getNextAideRoute(basket, options) {
  if (options.checkChannel && basket.channel !== CHANNEL.ALVA) return { name: ROUTE_NAME.AIDE.ENTRY }

  if (basket.persons.length === 0) return { name: ROUTE_NAME.AIDE.PERSONS }
}

export function getNextAvailableRoute(
  $route,
  config = {
    hidden: false,
    routeIndex: null,
    includeDisabledRoutes: false,
  }
) {
  const routes = getRoutes($route, basketStore.basket)
  const currentRoute = getRouteByIndex(routes, $route.meta.index)

  const startIndex = config.routeIndex ?? currentRoute.index

  return routes.find(r => {
    return (config.hidden || !r.hidden) && (config.includeDisabledRoutes || !r.disabled) && r.index > startIndex
  })
}

export function getNextRoute(basket) {
  if (basket.submitted) return ROUTE_NAME.NEXT_STEPS

  if (!isPersonalDataValid(basket.persons)) return ROUTE_NAME.PERSONS

  const hasProducts = basketHasProducts(basket.persons)

  if (hasProducts) {
    return ROUTE_NAME.BASKET
  } else {
    return ROUTE_NAME.OFFERS
  }
}

export function getPreviousNonHiddenRoute($route) {
  const routes = getRoutes($route, basketStore.basket)
  const reverseRoutes = cloneDeep(routes)
  reverseRoutes.sort(SortUtil.sortReverseByParam('index'))

  const currentRoute = getRouteByIndex(routes, $route.meta.index)
  return reverseRoutes.find(r => !r.hidden && !r.disabled && r.index < currentRoute.index)
}

export function getRouteAfterReentry(basket) {
  if (basket.submitted) return ROUTE_NAME.NEXT_STEPS

  if (!isPersonalDataValid(basket.persons)) return ROUTE_NAME.PERSONS
  return ROUTE_NAME.BASKET
}

function RouteConfig({
  complete = isRequired('complete'),
  disabled = isRequired('disabled'),
  hidden = isRequired('hidden'),
  name = isRequired('name'),
  invalid = isRequired('invalid'),
  isInNavigation = isRequired('isInNavigation'),
  match = isRequired('match'),
  small = isRequired('small'),
  path = isRequired('path'),
  text, // optional
  tooltip, // optional
  index = isRequired('index'),
}) {
  this.complete = complete
  this.disabled = disabled
  this.hidden = hidden
  this.name = name
  this.invalid = invalid
  this.isInNavigation = isInNavigation
  this.match = match
  this.small = small
  this.path = path
  this.text = text
  this.tooltip = tooltip
  this.index = index
}

function getRouteByIndex(routes, routeIndex) {
  return routes.find(r => r.index === routeIndex)
}

function getPersonRoute(
  $route,
  { basket = isRequired('basket') },
  { isEntryPersonsValid = isRequired('isEntryPersonsValid') }
) {
  const people = basket.persons.length

  return new RouteConfig({
    complete: people > 0,
    disabled: isStaticBasket.value,
    hidden: false,
    name: ROUTE_NAME.PERSONS,
    invalid: people > 0 && !isEntryPersonsValid,
    isInNavigation: true,
    match: $route.path.includes('/persons'),
    small: false,
    path: { name: ROUTE_NAME.PERSONS },
    tooltip: isStaticBasket.value ? 'temp.navigation.disabled' : undefined,
    index: ROUTE_INDEX.PERSONS,
  })
}

function getOfferRoute(
  $route,
  { basket = isRequired('basket') },
  { isEntryPersonsValid = isRequired('isEntryPersonsValid'), isOfferComplete = isRequired('isOfferComplete') }
) {
  const people = basket.persons.length
  const personId = filterPersonsToValidate(basket)[0]?.personId

  return new RouteConfig({
    complete: $route.meta.index > 1 || isOfferComplete,
    disabled: people === 0 || !isEntryPersonsValid || isStaticBasket.value,
    hidden: false,
    name: ROUTE_NAME.OFFERS,
    invalid: false,
    isInNavigation: true,
    match: $route.path.includes('/products'),
    small: false,
    path: {
      name: ROUTE_NAME.OFFERS,
      params: { personId },
    },

    tooltip: isStaticBasket.value ? 'temp.navigation.disabled' : undefined,
    index: ROUTE_INDEX.OFFERS,
  })
}

function getBasketRoute(
  $route,
  { basket = isRequired('basket') },
  { isEntryPersonsValid = isRequired('isEntryPersonsValid'), hasProducts, isBasketValidForAllProducts, isOfferComplete }
) {
  const metaIndex = $route.meta.index

  const people = basket.persons.length

  return new RouteConfig({
    complete: metaIndex > 2 || isOfferComplete,
    disabled: people === 0 || !isEntryPersonsValid,
    hidden: false,
    name: ROUTE_NAME.BASKET,
    invalid: !hasProducts && !isBasketValidForAllProducts,
    isInNavigation: true,
    match: $route.path.includes('/basket'),
    small: false,
    path: { name: ROUTE_NAME.BASKET },
    index: ROUTE_INDEX.BASKET,
  })
}

function getOnboardingRoute(
  $route,
  { isOnboardingSecured = isRequired('isOnboardingSecured'), isOfferValid = isRequired('isOfferValid') }
) {
  return new RouteConfig({
    complete: isOnboardingSecured,
    disabled: false,
    hidden: true,
    name: ROUTE_NAME.ONBOARDING,
    invalid: !($route.name === ROUTE_NAME.SUBMIT ? isOfferValid : true),
    isInNavigation: false,
    match: $route.path.includes('/onboarding'),
    small: false,
    path: { name: ROUTE_NAME.ONBOARDING },
    index: ROUTE_INDEX.ONBOARDING,
  })
}

function getApplicationRoute(
  $route,
  { basket = isRequired('basket') },
  {
    hasProducts = isRequired('hasProducts'),
    isAdminDataComplete = isRequired('isAdminDataComplete'),
    isBasketValidForSomeProducts = isRequired('isBasketValidForSomeProducts'),
    isOfferComplete = isRequired('isOfferComplete'),
    isOnboardingSecured = isRequired('isOnboardingSecured'),
    areHealthQuestionsCompletedForAllPersons = isRequired('areHealthQuestionsCompletedForAllPersons'),
    isPersonDataComplete = isRequired('isPersonDataComplete'),
  }
) {
  // @note: for existing customers, we only check the `dapStarted`-flag: FLEX-3315
  const isDisabled =
    ((!hasProducts || !isBasketValidForSomeProducts || !isOnboardingSecured) && !basket.existingCustomer) ||
    (basket.existingCustomer && !basket.dapStarted)

  return new RouteConfig({
    complete:
      isOfferComplete || (areHealthQuestionsCompletedForAllPersons && isPersonDataComplete && isAdminDataComplete),
    disabled: isDisabled,
    hidden: false,
    name: ROUTE_NAME.APPLICATION,
    invalid: !isOnboardingSecured,
    isInNavigation: true,
    match: $route.path.includes('/application'),
    small: false,
    path: { name: ROUTE_NAME.APPLICATION },
    index: ROUTE_INDEX.APPLICATION,
  })
}

function getExistingCustomerRoute(
  $route,
  { basket = isRequired('basket') },
  {
    isOnboardingSecured = isRequired('isOnboardingSecured'),
    isPersonDataComplete = isRequired('isPersonDataComplete'),
    isAdminDataComplete = isRequired('isAdminDataComplete'),
  }
) {
  /*
    TODO: These checks hide the existingCustomer-Screen when there are no data to enter for any person,
          but needs to be discussed with Gesa first.
   */

  /*  const noCollective = !basket.collective?.contractNumber || (basket.collective?.personnelNumber || basket.collective?.startDate)
  const noNewlyAddedPersons = basket.persons.filter(p => !p.partnerNumber).length === 0
  const noPreInsurer = basket.persons.filter(p => p.preInsurer).length === 0

  const hideRoute = noCollective && noNewlyAddedPersons && noPreInsurer
  console.log(noCollective, noNewlyAddedPersons, noPreInsurer)
  console.log("HIDE: ", noCollective && noNewlyAddedPersons && noPreInsurer)*/

  return new RouteConfig({
    complete: isPersonDataComplete && isAdminDataComplete,
    disabled: !isOnboardingSecured,
    hidden: !basket.existingCustomer || $route.meta.index < ROUTE_INDEX.ONBOARDING, // || hideRoute,
    name: ROUTE_NAME.APPLICATION_EXISTING_CUSTOMER_DATA,
    invalid: !isPersonDataComplete || !isAdminDataComplete,
    isInNavigation: false,
    match: $route.path.includes('/application/existing-customer-data'),
    small: true,
    path: { name: ROUTE_NAME.APPLICATION_EXISTING_CUSTOMER_DATA },
    index: ROUTE_INDEX.APPLICATION_EXISTING_CUSTOMER_DATA,
  })
}

function getPersonalDataRoute(
  $route,
  { basket = isRequired('basket') },
  { isPersonDataComplete = isRequired('isPersonDataComplete'), isOnboardingSecured = isRequired('isOnboardingSecured') }
) {
  return new RouteConfig({
    complete: isPersonDataComplete, // rename isComplete in general is and has for booleans
    disabled: !isOnboardingSecured,
    hidden: basket.existingCustomer || $route.meta.index < ROUTE_INDEX.ONBOARDING || !isOnboardingSecured,
    name: ROUTE_NAME.APPLICATION_PERSONAL_DATA,
    invalid: !isPersonDataComplete,
    isInNavigation: false, //  rename
    match: $route.path.includes('/application/personal-data/'),
    small: true,
    path: {
      name: ROUTE_NAME.APPLICATION_PERSONAL_DATA,
      params: { personId: basket.persons[0]?.personId },
    },
    index: ROUTE_INDEX.APPLICATION_PERSONAL_DATA,
  })
}

function getAdminDataRoute(
  $route,
  { basket = isRequired('basket') },
  { isAdminDataComplete = isRequired('isAdminDataComplete'), isOnboardingSecured = isRequired('isOnboardingSecured') }
) {
  return new RouteConfig({
    complete: isAdminDataComplete,
    disabled: !isOnboardingSecured,
    hidden: basket.existingCustomer || $route.meta.index < ROUTE_INDEX.ONBOARDING || !isOnboardingSecured,
    name: ROUTE_NAME.APPLICATION_ADMIN_DATA,
    invalid: !isAdminDataComplete,
    isInNavigation: false,
    match: $route.path.includes('/application/administrative-data'),
    small: true,
    path: { name: ROUTE_NAME.APPLICATION_ADMIN_DATA },
    index: ROUTE_INDEX.APPLICATION_ADMIN_DATA,
  })
}

function getHealthquestionRoute(
  $route,
  { basket = isRequired('basket') },
  {
    areHealthQuestionsCompletedForAllPersons = isRequired('areHealthQuestionsCompletedForAllPersons'),
    isOnboardingSecured = isRequired('isOnboardingSecured'),
  }
) {
  const hasHealthquestions =
    basket.persons.filter(p => p.healthQuestionAnswers && p.healthQuestionAnswers.length > 0).length > 0

  const hidden = !hasHealthquestions || !isOnboardingSecured || $route.meta.index < ROUTE_INDEX.ONBOARDING
  const personId = filterPersonsToValidate(basket)[0]?.personId

  return new RouteConfig({
    complete: areHealthQuestionsCompletedForAllPersons,
    disabled: !isOnboardingSecured,
    hidden: hidden,
    name: ROUTE_NAME.APPLICATION_HEALTHQUESTIONS,
    invalid: !areHealthQuestionsCompletedForAllPersons,
    isInNavigation: false,
    match: $route.path.includes('/application/healthquestions/'),
    small: true,
    path: {
      name: ROUTE_NAME.APPLICATION_HEALTHQUESTIONS,
      params: { personId },
    },
    index: ROUTE_INDEX.APPLICATION_HEALTHQUESTIONS,
  })
}

function getSubmitRoute(
  $route,
  { basket = isRequired('basket') },
  { isOfferComplete = isRequired('isOfferComplete'), isOfferValid = isRequired('isOfferValid') }
) {
  return new RouteConfig({
    complete: isOfferComplete,
    disabled: (!isOfferValid && !basket.existingCustomer) || (basket.existingCustomer && !basket.dapStarted),
    hidden: false,
    name: ROUTE_NAME.SUBMIT,
    invalid: false,
    isInNavigation: true,
    match: $route.path.includes('/submit'),
    small: false,
    text: '5',
    path: { name: ROUTE_NAME.SUBMIT },
    index: ROUTE_INDEX.SUBMIT,
  })
}

function getAdvisoryProtocolRoute($route) {
  const isInvalidFromRoute = $route.meta.index < ROUTE_INDEX.SUBMIT
  const hideProtocol = basketStore.basket.advisoryProtocol !== true

  return new RouteConfig({
    complete: false, // not shown in navigation, value irrelevant
    disabled: false, // not shown in navigation, value irrelevant
    hidden: hideProtocol || isInvalidFromRoute,
    name: ROUTE_NAME.ADVISORY_PROTOCOL,
    invalid: false, // not shown in navigation, value irrelevant
    isInNavigation: false,
    match: $route.path.includes('/advisory-protocol'),
    small: false, // not shown in navigation, value irrelevant
    path: { name: ROUTE_NAME.ADVISORY_PROTOCOL },
    index: ROUTE_INDEX.ADVISORY_PROTOCOL,
  })
}

function getSignatureEntryRoute($route, { basket = isRequired('basket') }) {
  const isInvalidFromRoute = $route.meta.index < ROUTE_INDEX.SUBMIT

  return new RouteConfig({
    complete: false, // not shown in navigation, value irrelevant
    disabled: false, // not shown in navigation, value irrelevant
    hidden: !basket.signature?.signatureRequired || isInvalidFromRoute,
    name: ROUTE_NAME.SIGNATURE_ENTRY,
    invalid: false, // not shown in navigation, value irrelevant
    isInNavigation: false,
    match: $route.path.includes('/digital-signature/entry'),
    small: false, // not shown in navigation, value irrelevant
    path: { name: ROUTE_NAME.SIGNATURE_ENTRY },
    index: ROUTE_INDEX.SIGNATURE_ENTRY,
  })
}

function getSignatureVerificationRoute($route, { basket = isRequired('basket') }) {
  const isInvalidFromRoute = $route.meta.index < ROUTE_INDEX.SUBMIT

  return new RouteConfig({
    complete: false, // not shown in navigation, value irrelevant
    disabled: false, // not shown in navigation, value irrelevant
    hidden: !basket.signature?.signatureRequired || isInvalidFromRoute,
    name: ROUTE_NAME.SIGNATURE_VERIFICATION,
    invalid: false, // not shown in navigation, value irrelevant
    isInNavigation: false,
    match: $route.path.includes('/digital-signature/verification'),
    small: false, // not shown in navigation, value irrelevant
    path: { name: ROUTE_NAME.SIGNATURE_VERIFICATION },
    index: ROUTE_INDEX.SIGNATURE_VERIFICATION,
  })
}

function getNextStepsRoute($route) {
  // special case reentry allow navigation via meta flag
  const { allowNavigationToNextSteps } = $route.meta
  const isInvalidFromRoute = allowNavigationToNextSteps ? false : $route.meta.index < ROUTE_INDEX.SUBMIT

  return new RouteConfig({
    complete: false, // not shown in navigation, value irrelevant
    disabled: false, // not shown in navigation, value irrelevant
    hidden: isInvalidFromRoute,
    name: ROUTE_NAME.NEXT_STEPS,
    invalid: false, // not shown in navigation, value irrelevant
    isInNavigation: false,
    match: $route.path.includes('/next-steps'),
    small: false, // not shown in navigation, value irrelevant
    path: { name: ROUTE_NAME.NEXT_STEPS },
    index: ROUTE_INDEX.NEXT_STEPS,
  })
}
