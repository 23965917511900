import { createBoundingBox } from '@/components/MedicalSearch/utils/map'
import { getNetworkFromProduct } from '@/components/MedicalSearch/utils/product'

export const boundsSearchOptions = bounds => {
  const boundingBox = createBoundingBox(bounds)
  return {
    minLat: boundingBox.min.lat,
    maxLat: boundingBox.max.lat,
    minLon: boundingBox.min.lng,
    maxLon: boundingBox.max.lng,
  }
}

export const createSearchPayload = (product, query) => {
  const avmNetwork = getNetworkFromProduct(product)

  return {
    ...query.options,
    avmNetwork,
    limit: query.limit,
    contractStartDate: query.contractStartDate,
  }
}

export const iSelectedIdInFilteredData = (filteredData, selectedId) => {
  if (!filteredData || !selectedId) return false
  return filteredData.some(data => data.zsrNumber === selectedId)
}

export const transformSearchResponse = response => {
  return response.map(item => {
    if (item && !item.id) {
      item.id = item.zsrNumber
    }
    return item
  })
}
