// import Vue from '@/setup'
import { getNextAideRoute } from '@/helpers/Route'
import { loading$ } from '@/services'

import basketStore from '@/store/basket'
import useI18n from '@/hooks/useI18n'

import { CATEGORY_TYPE, ROUTE_INDEX, ROUTE_NAME } from '@/config/constants'

// HOOKS
const { loadAideTranslation, selectedLanguage } = useI18n()

// 100 - 109
const entryRoutes = [
  {
    component: () => import('@/modules/Configurator/screens/Aide/Entry'),
    meta: {
      nav: false,
      index: ROUTE_INDEX.AIDE.ENTRY,
      secured: true,
      aide: true,
      avatar: false,
      needsProducts: false,
    },
    name: ROUTE_NAME.AIDE.ENTRY,
    path: '',
  },
]

// 110 - 119
const personRoutes = [
  {
    component: () => import('@/modules/Configurator/screens/Aide/Persons'),
    meta: {
      nav: true,
      aide: true,
      avatar: true,
      needsProducts: false,
      index: ROUTE_INDEX.AIDE.PERSONS,
      secured: true,
    },
    name: ROUTE_NAME.AIDE.PERSONS,
    path: 'persons',
  },
]

// 120 - 149
const offerRoutes = [
  {
    beforeEnter: (_to, _from, next) => {
      if (basketStore.basket.persons.length === 0) {
        next({
          name: ROUTE_NAME.AIDE.ENTRY,
        })
      } else {
        next()
      }
    },
    component: () => import('@/modules/Configurator/screens/Aide/Offers/Index'),
    path: 'offers',
    props: route => ({
      personId: route.params.personId,
    }),
    children: [
      {
        path: '',
        beforeEnter: (_to, _from, next) => {
          const personId = basketStore.basket.persons[0].personId

          next({
            name: ROUTE_NAME.AIDE.KVG,
            replace: true,
            props: {
              categoryId: CATEGORY_TYPE.KVG,
              personId,
            },
          })
        },
      },
      {
        name: ROUTE_NAME.AIDE.KVG,
        path: ':categoryId(KVG)/:personId',
        component: () => import('@/modules/Configurator/screens/Aide/Offers/KVG/Product'),
        meta: {
          nav: true,
          aide: true,
          avatar: true,
          needsProducts: true,
          index: ROUTE_INDEX.AIDE.KVG_PRODUCT,
          secured: true,
        },
        props: route => ({
          categoryId: CATEGORY_TYPE.KVG,
          personId: route.params.personId,
        }),
      },
      {
        name: ROUTE_NAME.AIDE.FRANCHISE,
        path: ':categoryId(KVG)/:personId/franchise',
        component: () => import('@/modules/Configurator/screens/Aide/Offers/KVG/Franchise'),
        meta: {
          nav: true,
          aide: true,
          avatar: true,
          needsProducts: true,
          index: ROUTE_INDEX.AIDE.KVG_PRODUCT_FRANCHISE,
          secured: true,
        },
        props: route => ({
          categoryId: CATEGORY_TYPE.KVG,
          personId: route.params.personId,
        }),
      },
      {
        name: ROUTE_NAME.AIDE.ACCIDENT,
        path: ':categoryId(KVG)/:personId/accident',
        component: () => import('@/modules/Configurator/screens/Aide/Offers/KVG/Accident'),
        meta: {
          nav: true,
          aide: true,
          avatar: true,
          needsProducts: true,
          index: ROUTE_INDEX.AIDE.KVG_PRODUCT_ACCIDENT,
          secured: true,
        },
        props: route => ({
          categoryId: CATEGORY_TYPE.KVG,
          personId: route.params.personId,
        }),
      },
      {
        component: () => import('@/modules/Configurator/screens/Aide/Offers/HOSP/Product'),
        name: ROUTE_NAME.AIDE.HOSP,
        path: ':categoryId(HOSP)/:personId',
        meta: {
          nav: true,
          aide: true,
          avatar: true,
          needsProducts: true,
          index: ROUTE_INDEX.AIDE.HOSP_PRODUCT,
          secured: true,
        },
        props: route => ({
          categoryId: CATEGORY_TYPE.HOSP,
          personId: route.params.personId,
        }),
      },
      {
        component: () => import('@/modules/Configurator/screens/Aide/Offers/HOSP/Upgrade'),
        name: ROUTE_NAME.AIDE.HOSP_UPGRADE,
        path: ':categoryId(HOSP)/:personId/upgrade',
        meta: {
          nav: true,
          aide: true,
          avatar: true,
          needsProducts: true,
          index: ROUTE_INDEX.AIDE.HOSP_PRODUCT_UPGRADE,
          secured: true,
        },
        props: route => ({
          categoryId: CATEGORY_TYPE.HOSP,
          personId: route.params.personId,
        }),
      },
      {
        component: () => import('@/modules/Configurator/screens/Aide/Offers/VVG/Groups'),
        name: ROUTE_NAME.AIDE.VVG,
        path: ':categoryId(ADDITIONAL)/:personId',
        meta: {
          nav: true,
          aide: true,
          avatar: true,
          needsProducts: true,
          index: ROUTE_INDEX.AIDE.VVG_GROUPS,
          secured: true,
        },
        props: route => ({
          categoryId: CATEGORY_TYPE.ADDITIONAL,
          personId: route.params.personId,
        }),
      },
      {
        component: () => import('@/modules/Configurator/screens/Aide/Offers/VVG/Group'),
        name: ROUTE_NAME.AIDE.ADDITIONAL,
        path: ':categoryId(ADDITIONAL)/:personId/:groups/:groupId',
        meta: {
          nav: true,
          aide: true,
          avatar: true,
          needsProducts: true,
          index: ROUTE_INDEX.AIDE.VVG_GROUP,
          secured: true,
        },
        props: route => ({
          categoryId: CATEGORY_TYPE.ADDITIONAL,
          personId: route.params.personId,
          groups: route.params.groups,
          groupId: route.params.groupId,
        }),
      },
    ],
  },
]

// 150 - 159
const basketRoutes = [
  {
    beforeEnter: (_to, _from, next) => {
      window.setTimeout(() => {
        const nextRoute = getNextAideRoute(basketStore.basket, {
          checkChannel: true,
        })

        if (nextRoute) {
          next(nextRoute)
        } else {
          next()
        }
      }, 150)
    },
    path: 'basket',
    name: ROUTE_NAME.AIDE.BASKET,
    component: () => import('@/modules/Configurator/screens/Aide/Basket'),
    meta: {
      nav: false,
      aide: true,
      avatar: false,
      needsProducts: true,
      index: ROUTE_INDEX.AIDE.BASKET,
      secured: true,
    },
  },
]

export default [
  {
    beforeEnter: async (_to, _from, next) => {
      loading$.start()
      await loadAideTranslation(selectedLanguage.value)
      loading$.end()
      next()
    },
    component: () => import('@/modules/Configurator/screens/Aide/Aide'),
    path: '/aide',
    meta: {
      disableNavigation: false,
      headerDisabled: false,
      navigation: false,
      secured: true,
    },
    children: [].concat(entryRoutes).concat(personRoutes).concat(offerRoutes).concat(basketRoutes),
  },
]
