import { isNil } from 'lodash'

import { logError } from '@/helpers/Logger'

export const BasketUtil = {
  getCustomerNumberFromContract(contractNumber) {
    return contractNumber.split('.')[0]
  },

  /**
   * @param {Person[]} persons
   * @returns {Number}
   */
  getTotal(persons) {
    // TODO: Can parameter be undefined?
    return persons.reduce((acc, value) => {
      if (isNil(value.total)) {
        logError(`person ${value.personId} has no total`)
      }
      acc += value.total
      return acc
    }, 0)
  },

  hasPersons(basket) {
    return !!basket.persons?.length
  },

  mapProductCriteria(product, p) {
    const crit = product.criteria

    const needsFranchiseCheck = crit.value !== undefined && p.value !== undefined
    const needsDurationCheck = false // crit.duration !== undefined && p.duration !== undefined
    const needsAccidentCheck = false // crit.toggle !== undefined && p.toggle !== undefined

    let isMatch = true

    if (needsFranchiseCheck && crit.value !== p.value) isMatch = false
    // no check necessary, because toggle is inherited and duration is irrelevant (for now)
    // if (needsDurationCheck && crit.duration !== p.duration) isMatch = false
    // if (needsAccidentCheck && crit.toggle !== p.toggle) isMatch = false

    // apply accident-value to all (applicable) criteria-selections
    if (p.toggle !== undefined) p.toggle = crit.toggle

    if ((needsFranchiseCheck || needsDurationCheck || needsAccidentCheck) && isMatch) {
      p.selected = true
    } else if (!needsFranchiseCheck && !needsDurationCheck && !needsAccidentCheck) {
      // don't do nothing, man!
    } else {
      p.selected = false
    }

    return p
  },

  selectProducts(personId, basket, fromProductIds, toProducts) {
    const pBasket = basket.persons.find(p => p.personId === personId)

    fromProductIds.forEach(productId => {
      // deselect old products
      pBasket.products.products[productId].selected = false
    })

    toProducts.forEach(product => {
      // select new product
      pBasket.products.products[product.productId].selected = true

      if (product.doctor) {
        pBasket.products.products[product.productId].doctor = product.doctor
      }

      // set default-criteria
      if (product.criteria) {
        pBasket.products.products[product.productId].prices.map(p => {
          return BasketUtil.mapProductCriteria(product, p)
        })
      }
    })

    return basket
  },
}
