<template>
  <!-- prettier-ignore -->
  <div :class="classes">
    <div
      v-show="showList"
      id="mos-maps__aside"
      class="mos-maps__aside"
    >
      <div class="results-form">
        <location-search
          :location="location"
          @search="handleLocationSearch"
        />
        <filters-group @filter="onFilter" />
      </div>
      <loading-alt v-if="isLoading" />
      <results-list
        v-else
        :auto-scroll="autoScroll"
        :product="$props.product"
        :results="sortedAndFilteredResults"
        @close="handleResultClose"
        @submit="handleSubmit"
      />
    </div>
    <div
      v-show="showMap"
      class="mos-maps__content"
    >
      <google-map
        :results="sortedAndFilteredResults"
        :location="location"
        :product="$props.product"
        :zoom-control="!isSMViewport"
        @loaded="mapLoaded = true"
        @reset="resetData"
        @select="handleMarkerSelect"
        @update="mapSearch"
        @markerContentHover="handleMarkerContentHover"
        @markerContentLeave="handleMarkerContentLeave"
        @submit="handleSubmit"
      />
    </div>
    <div
      v-if="isSMViewport"
      class="mos-maps__switch"
    >
      <basic-switch-button
        v-model="selectedTab"
        :items="tabs"
        class="mos-maps__switch"
        name="mos-maps-switch"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, onMounted, ref } from 'vue'
import { map, pick } from 'lodash'

import { ReactivityUtil } from '@/utils/Reactivity'

import useBrowser from '@/hooks/useBrowser'
import useMapSearch from '@/hooks/useMapSearch'

import { BasicSwitchButton } from '@/components/Basic'

import LoadingAlt from '@/components/Container/LoadingAlt'
import FiltersGroup from '@/components/MedicalSearch/components/FiltersGroup'
import GoogleMap from '@/components/MedicalSearch/components/GoogleMap'
import ResultsList from '@/components/MedicalSearch/components/ResultsList'
import LocationSearch from '@/components/MedicalSearch/components/LocationSearch'

// HOOKS
const { isSMViewport } = useBrowser()
const {
  autoScroll,
  filteredData,
  isSearching,
  location,
  selectedId,
  onFilter,
  onMarkerSelect,
  resetData,
  resetFilter,
  searchByBounds,
  searchByZipCode,
  setHoveredId,
  setLocation,
  startDate,
} = useMapSearch()
const selectedTab = ref('list')

// INIT
const emit = defineEmits(['close', 'submit'])
const props = defineProps({
  contractStartDate: {
    type: String,
    required: true,
  },
  options: {
    type: Object,
    required: true,
  },
  product: {
    type: [Object, Boolean],
    default: false,
  },
  limit: {
    type: Number,
    default: 300,
  },
})

// DATA
const mapLoaded = ref(false)
let enterTimeout
let leaveTimeout

// COMPUTED
const classes = computed(() => {
  return {
    'mos-maps': true,
    'mos-maps--selected': selectedId.value,
    'mos-maps--list-only': showList.value && !showMap.value,
  }
})

const tabs = computed(() => {
  return [
    {
      text: 'List',
      value: 'list',
    },
    {
      text: 'Map',
      value: 'map',
    },
  ]
})

const hideMap = computed(() => {
  return selectedTab.value === 'list'
})

const isLoading = computed(() => {
  return isSearching.value || (showMap.value && !mapLoaded.value)
})

const showList = computed(() => {
  return !isSMViewport.value || hideMap.value
})

const showMap = computed(() => {
  return !isSMViewport.value || !hideMap.value
})

/**
 * FLEX-2584
 * Sanacare needs to be pushed to the top
 */
const sortedAndFilteredResults = computed(() => {
  const key = 'Sanacare'
  const data = ReactivityUtil.clone(filteredData.value)

  return [...data.filter(item => item.lastName.includes(key)), ...data.filter(item => !item.lastName.includes(key))]
})

// METHODS
function handleMarkerContentHover(zsrNumber) {
  clearTimeout(leaveTimeout)
  enterTimeout = setTimeout(() => {
    if (zsrNumber) setHoveredId(zsrNumber)
  }, 10)
}

function handleMarkerContentLeave() {
  clearTimeout(enterTimeout)
  leaveTimeout = setTimeout(() => {
    setHoveredId()
  }, 10)
}

function handleMarkerSelect(markerOptions) {
  onMarkerSelect(markerOptions)
}

/**
 * Handles close event on message when no results are found
 */
function handleResultClose() {
  emit('close')
}

/**
 * Handles submit event to add medical office to product
 */
function handleSubmit() {
  const selectedOffice = filteredData.value.find(office => office.zsrNumber === selectedId.value)

  if (!selectedOffice) return

  submitOffice(selectedOffice)
}

/**
 * Triggers search for medical offices on map changes
 */
async function mapSearch(mapBounds) {
  await searchByBounds(mapBounds, props.product, props.limit)
}

/**
 * Submits the selected office to add it to the product
 */
function submitOffice(office) {
  const partialOfficeData = partialOffice => pick(partialOffice, ['description', 'avmDefinition', 'discountGroup'])
  const officeData = {
    ...office,
    medicalOfficeNets: map(office.medicalOfficeNets, partialOfficeData),
  }
  emit('submit', officeData)
}

async function handleLocationSearch(newLocation) {
  await setLocation(newLocation)
  if (!showMap.value) {
    await searchByZipCode(props.product, props.limit)
  }
}

// LIFECYCLE HOOKS
onBeforeMount(() => {
  setLocation(props.options.location)
})

onMounted(async () => {
  resetFilter()
  startDate.value = props.contractStartDate
  await searchByZipCode(props.product, props.limit)
})
</script>

<style scoped>
.mos-maps {
  height: 96vh;

  &__aside {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: hidden;
    width: auto;
    padding: 0;

    .results-form {
      padding-bottom: var(--dotcom-responsive-spacing-res-xxxs);
      border-bottom: 1px solid var(--outline);
    }
  }

  &__confirm {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 72px;
    padding: 12px 16px;
    background-color: var(--c-primary-neutral-2);

    button {
      width: 100%;
    }
  }

  &__icon {
    position: absolute;
    cursor: pointer;
    display: flex;
    z-index: var(--dvp-stack-level-element);
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    background: var(--c-primary-neutral-3);

    .icon {
      margin: auto;
    }
  }

  &__switch {
    position: absolute;
    display: flex;
    bottom: var(--dotcom-responsive-spacing-res-s);
    width: 100%;
    justify-content: center;
  }

  &--is-loading {
    .mos-maps__content {
      position: absolute;
      left: 5px;
      width: 100vw;
      height: 96vh;
    }
  }
}

@media (--v-large) {
  .mos-maps {
    display: flex;
    flex-direction: row;

    &__content {
      width: calc(100% - 400px);
    }

    &__aside {
      width: 400px;
      max-height: inherit;
      padding: var(--dotcom-responsive-gutter-responsive) var(--dotcom-responsive-gutter-responsive) 0
        var(--dotcom-responsive-gutter-responsive);
    }
  }
}
</style>
