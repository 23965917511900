<template>
  <!-- prettier-ignore -->
  <div
    class="loading"
    :class="classes"
  >
    <div
      v-if="state.blocking"
      class="loading__box"
    >
      <the-icon
        v-if="state.failed"
        class="loading__icon"
        :art="icon.art"
        :name="icon.name"
      />
      <img
        v-else
        class="loading__icon"
        :src="LoadingIcon"
      />
      <div
        class="loading__text"
        v-text="icon.text"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, watch } from 'vue'

import { loading$ } from '@/services'

import useI18n from '@/hooks/useI18n'

import LoadingIcon from '@/assets/loading.svg'

// HOOKS
const { t, te } = useI18n()

// DATA
const state = reactive({
  blocking: false,
  closing: false,
  open: false,
  opening: false,
  text: null,
})
let timer

// COMPUTED
const classes = computed(() => {
  return {
    'loading--blocking': state.blocking,
    'loading--open': state.open,
    'loading--opening': state.opening,
    'loading--closing': state.closing,
    'loading--failed': state.failed,
  }
})

const icon = computed(() => {
  if (state.failed) {
    return {
      art: 'light',
      name: 'xmark',
      spin: false,
      text: 'Error!',
    }
  } else if (state.finished) {
    return {
      art: 'light',
      name: 'check',
      spin: false,
      text: null,
    }
  } else {
    return {
      art: 'duo',
      name: 'loader',
      spin: true,
      text: state.text,
    }
  }
})

// METHODS
function activate(options = {}) {
  state.blocking = options.blocking
  state.opening = true
  state.failed = false

  state.text = te('form.saving') ? t('form.saving') : null

  clearTimeout(timer)
  timer = window.setTimeout(() => {
    state.opening = false
    state.open = true
  }, 500)
}

function close() {
  state.closing = true

  window.setTimeout(() => {
    state.closing = false
    state.open = false
  }, 510)
}

function reset() {
  clearTimeout(timer)

  if (state.opening) {
    state.opening = false
    state.open = true
  }
  if (!state.open) return

  close()
}

// WATCHERS
watch(
  () => loading$.getState(),
  event => {
    const hasFailed = event.failed.value
    const isActive = event.active.value

    if (isActive) {
      activate(event.options)
    } else if (hasFailed) {
      state.failed = true

      window.setTimeout(() => {
        reset()
      }, 2000)
    } else if (!isActive) {
      reset()
    }
  },
  { deep: true, immediate: true }
)
</script>

<style name="mobile" scoped>
.loading {
  position: fixed;
  z-index: var(--dvp-stack-level-screen-blocker);

  background-color: transparent;

  .loading__box {
    position: absolute;
    left: calc(50% - 150px);
    top: calc(50% - 75px);

    display: none;

    flex-direction: column;
    gap: 24px;
    width: 320px;
    padding: 20px;
    text-align: center;

    .loading__icon {
      height: 75px;
    }

    .loading__text {
      color: var(--c-primary-neutral-1);
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      margin-top: -20px;
    }
  }

  &--blocking {
    background-color: rgba(255, 255, 255, 0.64);
  }

  &--opening {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .loading__box {
      display: flex;
    }
  }

  &--open {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .loading__box {
      display: flex;
    }
  }

  &--failed .loading__box .loading__icon {
    color: var(--c-secondary-color-1);
  }
}
</style>
