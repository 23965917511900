import { getNextAvailableRoute } from '@/helpers/Route'

import useStatus from '@/hooks/useStatus'

import { ROUTE_INDEX, ROUTE_NAME } from '@/config/constants'

// HOOKS
const { isOnboardingSecured, routeStates } = useStatus()

export default [
  {
    beforeEnter: function (to, _from, next) {
      const { hasProducts, isBasketValidForAllProducts } = routeStates.value

      // Check products -> if screen gets entered directly, then it should reroute to basket
      if (!isBasketValidForAllProducts || !hasProducts) {
        next({ name: ROUTE_NAME.BASKET })
      } else if (isOnboardingSecured.value) {
        const nextRoute = getNextAvailableRoute(to)
        next(Object.assign({}, nextRoute.path, { replace: true }))
      } else {
        next()
      }
    },
    component: () => import('@/modules/Configurator/screens/ScreenOnboarding'),
    path: '/onboarding',
    meta: {
      index: ROUTE_INDEX.ONBOARDING,
      navigation: false,
    },
    name: ROUTE_NAME.ONBOARDING,
  },
]
