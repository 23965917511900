import basketStore from '@/store/basket'
import useContract from '@/hooks/useContract'
import usePerson from '@/hooks/usePerson'
import useProduct from '@/hooks/useProduct'

import { PRODUCT_STATE } from '@/config/constants'
import { isRequired } from '@/utils/CustomErrors'

export default function useProductState() {
  // HOOKS
  const { getContractPerson, getContractProduct, getSelectedProductsOfContractPerson } = useContract()
  const { getPersonByPartnerNumber } = usePerson()
  const { getReplaceableProducts, getSelectedProductsOfPerson } = useProduct()

  // METHODS
  function state(partnerNumber, productId = isRequired('productId')) {
    if (!partnerNumber) return PRODUCT_STATE.EDITABLE
    if (!basketStore.basket.dynamic) return PRODUCT_STATE.READONLY

    const person = getPersonByPartnerNumber(partnerNumber)
    const basketProduct = getSelectedProductsOfPerson(person.products.products).find(p => p.productId === productId)

    // Channel switch with newly added person, therefore existing customer not in contract-persons array
    const contractPerson = getContractPerson(partnerNumber)
    if (!contractPerson) return PRODUCT_STATE.EDITABLE

    const contractProducts = getSelectedProductsOfContractPerson(partnerNumber)
    const contractProductById = contractProducts.find(p => p.productId === productId)

    const isProductWithinGroupAlreadySelected = () => {
      if (contractProductById && contractProductById.selected) return true

      const replaceableProducts = getReplaceableProducts(productId)
      return replaceableProducts.find(__productId => getContractProduct(partnerNumber, __productId)?.selected)
    }

    if (basketProduct?.selected && !isProductWithinGroupAlreadySelected()) {
      return PRODUCT_STATE.EDITABLE
    }

    // TODO: is second condition necessary?
    if (basketProduct?.selected && isProductWithinGroupAlreadySelected()) {
      if (basketProduct.price.duration && basketProduct.price.duration !== '1') {
        return PRODUCT_STATE.READONLY
      } else {
        return PRODUCT_STATE.NOT_DELETABLE
      }
    }
    return PRODUCT_STATE.EDITABLE
  }

  // Expose
  return {
    // fn
    state,
  }
}
