import { computed } from 'vue'
import { isEqual, isNil } from 'lodash'

import { logWarning } from '@/helpers/Logger'
import { isProductReloadNecessary } from '@/utils/BasketConfig'
import { isRequired } from '@/utils/CustomErrors'
import { DateTimeUtil } from '@/utils/DateTime'
import { confirm } from '@/utils/Modal'

import useHealthquestion from '@/components/Healthquestion/useHealthquestion'

import basketStore from '@/store/basket'
import partnerStore from '@/store/partner'
import useContract from '@/hooks/useContract'
import usePerson from '@/hooks/usePerson'
import useProduct from '@/hooks/useProduct'
import useProductPersons from '@/hooks/useProductPersons'
import { GENDER } from '@/config/constants'

export default function useStatus() {
  // HOOKS
  const { isLegalRepOrNonCustomerContractOwner } = useContract()
  const { getSelectedProductsOfPerson } = useProduct()
  const { hasSelectedProducts, selectedCategories } = useProductPersons()
  const { healthQuestionConfig } = useHealthquestion()

  // COMPUTED
  const routeStates = computed(() => {
    const { selectedCategoriesByPerson } = useProductPersons()
    const { persons } = basketStore.basket

    return {
      isAdminDataComplete: isAdminDataComplete(basketStore.basket),
      hasProducts: !!hasSelectedProducts.value,
      isBasketValidForAllProducts: basketIsValid(basketStore.basket),
      isBasketValidForSomeProducts: basketIsValid(basketStore.basket, false),
      isEntryPersonsValid: isPersonalDataValid(persons),
      areHealthQuestionsCompletedForAllPersons: isHealthQuestionsComplete(persons),
      isOfferComplete: offerComplete(basketStore.basket, persons),
      isOfferValid: offerValid(basketStore.basket, persons),
      isOnboardingSecured: isOnboardingSecured.value,
      isPersonDataComplete: isPersonDataComplete(basketStore.basket, selectedCategoriesByPerson.value),
    }
  })

  const isOnboardingSecured = computed(() => {
    return !!basketStore.basket.email && !!basketStore.basket.mobile
  })

  // METHODS
  /**
   * Ask for confirmation only if onboarded (or forced = true).
   * @param label
   * @param forced
   * @returns {Promise<unknown>}
   */
  async function askChangeConfirmation(label, forced = false) {
    const secured = isOnboardingSecured.value

    if (!secured && !forced) {
      return true
    } else {
      return await confirm({ label })
    }
  }

  // returns true, if there are any product selected
  function basketHasProducts(persons) {
    if (persons.length === 0) return false

    return persons.some(person => {
      const selectedProducts = getSelectedProductsOfPerson(person.products.products)
      return selectedProducts.length > 0
    })
  }

  // returns false, if there are no persons, or none of the persons has a product selected
  function basketIsComplete(basket) {
    const { filterPersonsToValidate } = usePerson()
    const persons = filterPersonsToValidate(basket)
    if (persons?.length === 0) return false
    let valid = true

    persons.forEach(person => {
      const selectedProducts = getSelectedProductsOfPerson(person.products.products)

      if (selectedProducts.length === 0) {
        valid = false
      }
    })

    return valid
  }

  /**
   * isAdminDataComplete checks if all data has been entered on the admin data screen
   *
   * @param basket
   * @return {boolean}
   */
  function isAdminDataComplete(payload) {
    const { address, collective, customerPortal, existingCustomer, payment } = payload

    const customerPortalComplete =
      existingCustomer ||
      customerPortal?.customerPortalAdvAccepted === false ||
      !!(customerPortal?.customerPortalAdvAccepted && customerPortal?.passwordDeliveryMethod)

    const addressComplete = !!(address?.street && address?.houseNumber)
    const collectiveComplete = isCollectiveComplete(collective)
    const _contractOwnerComplete = isContractOwnerComplete(payload)
    const paymentComplete = (!!payment?.paymentType && !!payment?.paymentFrequency) || payload.existingCustomer

    return addressComplete && _contractOwnerComplete && collectiveComplete && customerPortalComplete && paymentComplete
  }

  /**
   * isCollectiveComplete checks if all fields are filled in the collective-box
   *
   * @param {collective} payload
   * @return {boolean}
   */
  function isCollectiveComplete(payload) {
    if (!payload || Object.keys(payload).length === 0) return true

    if (partnerStore.isPersonnelNumberRequired.value) return !isNil(payload.personnelNumber)

    return true
  }

  function isConfirmationNeeded(updated, original) {
    if (!isOnboardingSecured.value) return false
    if (isProductReloadNecessary(updated, original)) return true

    if (updated.persons.length !== original.persons.length) return true

    if (!isEqual(updated.address, original.address)) return true

    return updated.persons.some(
      (person, index) =>
        person.personData.dateOfBirth !== original.persons[index].personData.dateOfBirth ||
        person.personData.gender !== original.persons[index].personData.gender ||
        person.collectivePersonGroup !== original.persons[index].collectivePersonGroup
    )
  }

  function isContractOwnerComplete(payload) {
    const owner = payload?.contractOwner
    if (isNil(owner)) return false
    if (isNil(owner.contractOwnerType)) return false
    if (isNil(payload?.email) || isNil(payload?.mobile)) return false

    if (owner.contractOwnerType === 'PERSONID' && isNil(owner.personId)) return false
    if (owner.contractOwnerType === 'CUSTOMER' && (isNil(owner.name) || isNil(owner.customerNr))) return false
    if (
      isLegalRepOrNonCustomerContractOwner(owner) &&
      (isNil(owner.lastName === null) ||
        isNil(owner.firstName) ||
        isNil(owner.gender) ||
        isNil(owner.dateOfBirth) ||
        isNil(owner.nationality))
    )
      return false
    if (
      isLegalRepOrNonCustomerContractOwner(owner) &&
      owner.sameAddress === false &&
      (isNil(owner.legalAddress?.street) || isNil(owner.legalAddress?.houseNumber))
    )
      return false

    return true
  }

  /**
   *  basketIsValid checks if the persons are valid and depending on the param 'validateAllProducts'
   *  checks for the validity of the products
   *
   *  @param validateAllProducts  if true checks that all persons have no or only valid products
   *                              otherwise checks if every person has at lease one valid product
   */
  function basketIsValid(basket, validateAllProducts = true) {
    if (!basket.persons || basket.persons?.length === 0) return false

    const { filterPersonsToValidate } = usePerson()
    const personsToValidate = filterPersonsToValidate(basket)

    if (!isPersonalDataValid(personsToValidate)) return false

    return personsToValidate.every(person => {
      const selectedProducts = getSelectedProductsOfPerson(person.products.products)
      const hasPersonOnlyValidProducts = selectedProducts.length === 0 || selectedProducts.every(p => p.valid)
      const hasPersonAtLeastOneValidProduct = selectedProducts.some(p => p.valid)

      return validateAllProducts ? hasPersonOnlyValidProducts : hasPersonAtLeastOneValidProduct
    })
  }

  /**
   * isPersonalDataValid checks if all fields on the person screen are valid
   *
   * @param persons
   * @return {boolean}
   */
  function isPersonalDataValid(persons, strict = false) {
    if (persons?.length === 0) return false

    const isValidPerson = p => {
      const age = DateTimeUtil.getAge(p?.personData?.dateOfBirth)

      const hasDateOfBirth = p.personData.dateOfBirth
      const hasGender = p.personData.gender
      const hasValidAge =
        (age === -1 && p.personData.gender === GENDER.UNBORN) || (age > -1 && p.personData.gender !== GENDER.UNBORN)

      let hasValidImmigration
      if (strict) {
        hasValidImmigration =
          (basketStore.basket.existingCustomer && !p.contractModified) ||
          !p.immigrant ||
          (p.immigrant && !!p.preInsurer.currentRegistration && !!p.personData.residencePermit)
      } else {
        hasValidImmigration =
          (basketStore.basket.existingCustomer && !p.contractModified) ||
          !p.immigrant ||
          (p.immigrant && !!p.preInsurer.currentRegistration)
      }

      return hasDateOfBirth && hasGender && hasValidAge && hasValidImmigration
    }

    return persons?.every(isValidPerson)
  }

  /**
   * isHealthQuestionsComplete checks if all healthQuestions are answered for all persons
   *
   * @param persons
   * @return {boolean}
   */
  function isHealthQuestionsComplete(persons) {
    return persons?.every(p => isHealthQuestionsCompleteForPerson(p.healthQuestionAnswers))
  }

  /**
   * isHealthQuestionsCompleteForPerson checks if a person has answered all available healthQuestions
   *
   * @param answers
   * @return {boolean}
   */
  function isHealthQuestionsCompleteForPerson(answers) {
    if (answers === undefined || answers.length === 0) {
      return true
    }

    return answers.every(hq => {
      const isValid = healthQuestionConfig(hq, answers)
      if (isNil(isValid)) {
        logWarning(`status-validation missing for ${hq.healthquestionId}`)
        return false
      }
      return isValid
    })
  }

  function offerComplete(basket, persons) {
    return (
      isAdminDataComplete(basket) &&
      basketIsComplete(basket) &&
      isHealthQuestionsComplete(persons) &&
      isOnboardingSecured.value &&
      isPersonDataComplete(basket, selectedCategories.value)
    )
  }

  function offerValid(basket, persons) {
    return basketIsValid(basket) && offerComplete(basket, persons) && isPersonalDataValid(persons, true)
  }

  /**
   * isPersonDataComplete checks if all the fields on the person-data screen are filled
   *
   * @param basket
   * @param selectedCategoriesMap
   * @return {boolean}
   */
  function isPersonDataComplete(basket, selectedCategoriesMap) {
    const { filterPersonsToValidate } = usePerson()
    const persons = filterPersonsToValidate(basket)

    const personalDataComplete = persons?.every(person => isPersonalDataCompleteForPerson(person.personData))

    const preInsurerComplete = persons?.every(person => {
      return isPreInsurerCompleteForPerson({
        immigrant: person.immigrant,
        preInsurer: person.preInsurer,
        kvgContractStartDate: person.kvgContractStartDate,
        isVVGOnly: selectedCategoriesMap[person.personId]?.isVVGOnly || undefined,
      })
    })

    //FLEX-1027: No work-situation questions for existing customers
    const workSituationComplete = basket.existingCustomer
      ? true
      : persons?.every(person => {
          return isWorkSituationCompleteForPerson(person.workSituation, {
            isVVGOnly: selectedCategoriesMap[person.personId].isVVGOnly,
            isKVGOnly: selectedCategoriesMap[person.personId].isKVGOnly,
          })
        })

    return personalDataComplete && preInsurerComplete && workSituationComplete
  }

  /**
   * isPersonalDataCompleteForPerson checks if all fields are filled in the
   * personalData-box on the person-data screen for a person
   *
   * @param personData
   * @return {boolean}
   */
  function isPersonalDataCompleteForPerson(personData = isRequired('personData')) {
    const hasValidNationalityData = () => {
      /*
      There can be incomplete data (missing nationality or residencePermit) coming from service-portal
      which needs to be handled as valid in DVP
     */
      if (basketStore.basket.existingCustomer) return true

      return personData.nationality && (personData.nationality === 'CH' || personData.residencePermit)
    }
    return !!(
      personData.dateOfBirth &&
      personData.firstName &&
      personData.lastName &&
      hasValidNationalityData() &&
      personData.gender
    )
  }

  /**
   * isPreInsurerCompleteForPerson checks if the constellation of preInsurer data is valid for a person
   *
   * @param preInsurer
   * @param kvgContractStartDate
   * @param isVVGOnly
   * @return {boolean}
   */
  function isPreInsurerCompleteForPerson({
    immigrant,
    preInsurer,
    kvgContractStartDate = isRequired('kvgContractStartDate'),
    isVVGOnly,
  }) {
    if (!preInsurer || isVVGOnly) return true
    if (isNil(preInsurer.preInsurer)) return false

    const hasPreInsurer = preInsurer.preInsurer === true
    const isFirstOfYear = kvgContractStartDate.slice(5, 10) === '01-01'

    // Checks if there is a preInsurer
    // FLEX-3772: We no longer want the user to be able to select 'Sanitas' (id: 362) as a pre-insurer
    if (hasPreInsurer) {
      return (
        !isNil(preInsurer.currentPreInsurerId) &&
        preInsurer.currentPreInsurerId !== '362' &&
        (isFirstOfYear ? true : !isNil(preInsurer.currentFranchise))
      )
    }

    // Checks for reason of no pre insurer
    if (!immigrant && !hasPreInsurer) return !isNil(preInsurer.currentKvgOtherReason)

    // There is no preInsurer and user has moved KVG to CH
    return true
  }

  /**
   * isWorkSituationCompleteForPerson checks if all fields in the workSituation-box are filled for a person
   *
   * @param workSituation
   * @param isVVGOnly
   * @param isKVGOnly
   * @return {boolean}
   */
  function isWorkSituationCompleteForPerson(
    workSituation,
    { isVVGOnly = isRequired('isVVGOnly'), isKVGOnly = isRequired('isKVGOnly') }
  ) {
    const { getWorkSituationFields } = usePerson()
    // Check if a person < 15 ever has a work situation object (as the backend doesn't include it)
    if (isNil(workSituation)) return true

    const workSituationFields = getWorkSituationFields({ isVVGOnly, isKVGOnly }, workSituation)

    return workSituationFields.filter(field => field.required).every(field => !isNil(workSituation[field.name]))
  }

  // Expose
  return {
    // cd
    routeStates,
    isOnboardingSecured,

    // fn
    askChangeConfirmation,
    basketIsComplete,
    isAdminDataComplete,
    isCollectiveComplete,
    isConfirmationNeeded,
    isContractOwnerComplete,
    isHealthQuestionsComplete,
    isHealthQuestionsCompleteForPerson,
    isPersonDataComplete,
    isPersonalDataCompleteForPerson,
    isPersonalDataValid,
    isPreInsurerCompleteForPerson,
    isWorkSituationCompleteForPerson,
    basketHasProducts,
    basketIsValid,
    offerValid,
  }
}
