import { getNextAvailableRoute } from '@/helpers/Route'

import productStructureStore from '@/store/productStructure'
import useStatus from '@/hooks/useStatus'

import { ROUTE_INDEX, ROUTE_NAME } from '@/config/constants'

// HOOKS
const { routeStates } = useStatus()

export default [
  {
    beforeEnter: function (to, _from, next) {
      const { hasProducts, isBasketValidForAllProducts, isOnboardingSecured } = routeStates.value

      // basket is invalid => route to persons
      if (!isBasketValidForAllProducts || !hasProducts) {
        next({ name: ROUTE_NAME.PERSONS })

        // basket valid => do the rest
      } else {
        let nextRoute = getNextAvailableRoute(
          {
            name: ROUTE_NAME.APPLICATION,
            path: '/application',
            meta: {
              index: ROUTE_INDEX.APPLICATION,
            },
          },
          {},
          true
        )?.path

        if (!nextRoute) nextRoute = { name: ROUTE_NAME.PERSONS }

        nextRoute = Object.assign({}, nextRoute, { replace: true })

        // onboarding not yet done -> go back!
        if (!isOnboardingSecured) {
          next({ name: ROUTE_NAME.ONBOARDING, replace: true })

          // to application -> redirect to first real app-screen
        } else if (to.name === ROUTE_NAME.APPLICATION) {
          next(nextRoute)
        } else {
          next()
        }
      }
    },
    component: () => import('@/modules/Configurator/screens/Application/Application'),
    path: '/application',
    name: ROUTE_NAME.APPLICATION,
    children: [
      {
        beforeEnter: function (_to, _from, next) {
          productStructureStore.ready().then(() => next())
        },
        component: () => import('@/modules/Configurator/screens/Application/ScreenExistingCustomerData'),
        meta: {
          index: ROUTE_INDEX.APPLICATION_EXISTING_CUSTOMER_DATA,
          basket: {
            readonly: true,
          },
          hideDefaultOverviewLink: true,
        },
        name: ROUTE_NAME.APPLICATION_EXISTING_CUSTOMER_DATA,
        path: 'existing-customer-data',
      },
      {
        component: () => import('@/modules/Configurator/screens/Application/ScreenPersonalData'),
        meta: {
          index: ROUTE_INDEX.APPLICATION_PERSONAL_DATA,
          basket: {
            readonly: true,
          },
          hideDefaultOverviewLink: true,
        },
        name: ROUTE_NAME.APPLICATION_PERSONAL_DATA,
        path: 'personal-data/:personId?',
        props: route => ({
          personId: route.params.personId,
        }),
      },
      {
        component: () => import('@/modules/Configurator/screens/Application/ScreenAdminData'),
        meta: {
          index: ROUTE_INDEX.APPLICATION_ADMIN_DATA,
          basket: {
            readonly: true,
          },
          hideDefaultOverviewLink: true,
        },
        name: ROUTE_NAME.APPLICATION_ADMIN_DATA,
        path: 'administrative-data',
      },
      {
        component: () => import('@/modules/Configurator/screens/Application/ScreenHealthquestions'),
        meta: {
          index: ROUTE_INDEX.APPLICATION_HEALTHQUESTIONS,
          basket: {
            readonly: true,
          },
          hideDefaultOverviewLink: true,
        },
        name: ROUTE_NAME.APPLICATION_HEALTHQUESTIONS,
        path: 'healthquestions/:personId?',
        props: route => ({
          personId: route.params.personId,
        }),
      },
    ],
  },
]
