import { v4 as uuidv4 } from 'uuid'

import events$ from '@/services/Events'
import loading$ from '@/services/Loading'

import { logError, logInfo } from '@/helpers/Logger'

import basketStore from '@/store/basket'
import sessionStore from '@/store/session'
import useApplication from '@/hooks/useApplication'
import useSession from '@/hooks/useSession'
import useStatus from '@/hooks/useStatus'

import { ROUTE_NAME, STORAGE_KEY } from '@/config/constants'
import { EVENT_OVP, EVENT_URL } from '@/config/events'

// GLOBAL DATA
let eventHandler = false

export default function useSync() {
  // HOOKS
  const { isWww } = useApplication()
  const { resumeSession } = useSession()
  const { isOnboardingSecured } = useStatus()

  // METHODS
  function registerSync() {
    // i'm new here, can you please give me a name?
    if (!window.name) {
      window.name = uuidv4()
    }

    eventHandler = handleChangeEvent
    window.addEventListener('storage', eventHandler)

    logInfo('SYNC REGISTERED')
  }

  function unregisterSync() {
    if (eventHandler) window.removeEventListener('storage', eventHandler)
    eventHandler = false

    logInfo('SYNC UNREGISTERED')
  }

  async function handleChangeEvent(event) {
    if (event.key !== STORAGE_KEY) return
    if (event.newValue === null) return

    const oldData = JSON.parse(event.oldValue)
    const newData = JSON.parse(event.newValue)

    // already newest state => abort!
    if (oldData?.updateDate === newData?.updateDate) return

    try {
      loading$.start()

      const __basket = await syncSession(newData)

      logInfo('SESSION SYNCED')
      events$.emit(EVENT_OVP.SYNC_EXECUTED)

      // routing only needs to happen in DVP
      if (!isWww.value) {
        // other-tab has submitted, now we need to go to the correct route
        if (__basket.submitted) {
          events$.emit(EVENT_URL.GOTO, {
            type: 'replace',
            route: {
              name: ROUTE_NAME.NEXT_STEPS,
            },
          })
        } else if (isOnboardingSecured.value) {
          events$.emit(EVENT_URL.GOTO, {
            type: 'replace',
            route: {
              name: ROUTE_NAME.APPLICATION,
            },
          })
        }
        // just reload for dotcom
      } else if (isOnboardingSecured.value) {
        window.location.reload()
      }

      loading$.end()
    } catch (error) {
      logError(['Failed to sync session after local storage update\n', error, event.oldValue, event.newValue])
      return error
    }
  }

  async function syncSession(basketFromStorage) {
    const __basket = await resumeSession(basketFromStorage)

    await sessionStore.setSession(__basket, { hot: true, setOwner: false })
    await basketStore.persistBasket(__basket)

    return __basket
  }

  return {
    registerSync,
    unregisterSync,
  }
}
