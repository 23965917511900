import { isRequired } from '@/utils/CustomErrors'

import config from '@/config'

import useBackend from '@/hooks/useBackend'

function mobileNumberVerification() {
  // HOOKS
  const { buildURL, setup, doGet, doPost } = useBackend()

  // INIT
  setup('/ovpv3/public', config.microserviceBaseUrl)

  // METHODS

  /**
   * sendSMSTanOnLogin sends a POST-request to /mobile-number/{$basketId}/send-sms-tan to verify the mobile number
   * @param {string} basketId
   * @param {string} mobileNumber
   * @returns Promise<null>
   */
  async function sendSMSTanOnLogin({ basketId = isRequired('basketId'), mobileNumber = isRequired('mobileNumber') }) {
    const url = buildURL(`/mobile-number/${basketId}/send-sms-tan`, { mobileNumber })
    const response = await doPost({ url })
    return response.data
  }

  /**
   * verifySMSTanOnLogin sends a GET-request to /mobile-number/${basketId}/verify-sms-tan to verify the mobile number based on the sms tan
   * @param {string} basketId
   * @param {string} mobileNumberVerificationSmsTan
   * @returns Promise<null>
   */
  async function verifySMSTanOnLogin({
    basketId = isRequired('basketId'),
    mobileNumberVerificationSmsTan = isRequired('mobileNumberVerificationSmsTan'),
  }) {
    const url = buildURL(`/mobile-number/${basketId}/verify-sms-tan`, { mobileNumberVerificationSmsTan })
    const response = await doGet({ url })
    return response.data
  }

  return {
    sendSMSTanOnLogin,
    verifySMSTanOnLogin,
  }
}

export default mobileNumberVerification()
