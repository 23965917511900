import { getNextRoute } from '@/helpers/Route'

import basketStore from '@/store/basket'

import { ROUTE_NAME } from '@/config/constants'

export default [
  {
    beforeEnter: function (_to, _from, next) {
      next({ name: getNextRoute(basketStore.basket) })
    },
    component: () => {},
    name: ROUTE_NAME.NOT_FOUND,
    path: '/:pathMatch(.*)*',
  },
]
