<template>
  <!-- prettier-ignore -->
  <div
    ref="avatarRef"
    class="avatar"
  >
    <div class="avatar-image">
      <img
        alt="avatar"
        class="avatar__image"
        :src="src"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'

import AlvaDesktopHappy from '@/assets/alva/Alva_Desktop_froehlich_00.06.svg'

// INIT
const MOODS = { happy: AlvaDesktopHappy }
const props = defineProps({
  mood: {
    type: String,
    default: 'happy',
  },
})

// DATA
const avatarRef = ref(undefined)

const src = computed(() => {
  return MOODS[props.mood]
})
</script>

<style scoped>
.avatar {
  &-image {
    position: relative;
  }

  &__image {
    height: 69px;
    width: 64px;
    position: absolute;
    left: calc(50% - 32px);
    top: 0;
    transition: all 0.75s ease-in-out;
  }
}

.entry__avatar {
  .avatar-image {
    justify-content: left;
  }
  .avatar__image {
    left: 0;
  }
}

@media (--v-medium) {
  .default__offer__avatar {
    .avatar-image {
      justify-content: left;
    }
    .avatar__image {
      left: 0;
    }
  }
}

@media (--v-large) {
  .avatar {
    &__image {
      left: 64px;
    }
  }
}

@media (--v-wide) {
  .entry__avatar,
  .default__offer__avatar {
    .avatar-image,
    .avatar__image {
      width: 92px;
      height: 99px;
    }
  }
}
</style>
