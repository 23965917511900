import { nextTick } from 'vue'

import { loading$ } from '@/services'
import { getNextRoute } from '@/helpers/Route'

import productStructureStore from '@/store/productStructure'
import basketStore from '@/store/basket'
import useStatus from '@/hooks/useStatus'

import { ROUTE_INDEX, ROUTE_NAME } from '@/config/constants'

// HOOKS
const { offerValid } = useStatus()

function reroute(from, next) {
  if (from.name) {
    next({
      name: from.name,
      params: from.params,
    })
  } else {
    next({
      name: getNextRoute(basketStore.basket),
      replace: true,
    })
  }
}

export default [
  {
    beforeEnter: (_to, from, next) => {
      // Trying to access url without a basket at all
      if (basketStore.isTemporaryBasket()) return reroute(from, next)

      productStructureStore.ready().then(() => {
        if (!offerValid(basketStore.basket, basketStore.basket.persons)) {
          loading$.end()
          return reroute(from, next)
        } else {
          try {
            // @note: loading could already be active from healthquestions
            if (!loading$.getState().active.value) loading$.start()

            // @note: If we don't use nextTick here,
            // then the UI will just skip the loading$.start()
            // and between hq and the submit-step, it will look like a hangup
            nextTick(() => {
              // FLEX-1790 always send submitScreen param before entering submit screen
              basketStore.updateOvpBasket({}, {}, { submitScreen: true }).then(() => {
                loading$.end()
                next()
              })
            })
          } catch {
            loading$.failed()
          }
        }
      })
    },
    component: () => import('@/modules/Configurator/screens/ScreenSubmit'),
    meta: {
      index: ROUTE_INDEX.SUBMIT,
      basket: {
        readonly: true,
      },
    },
    name: ROUTE_NAME.SUBMIT,
    path: '/submit',
  },
]
