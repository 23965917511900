<template>
  <label
    class="radio"
    :class="classes"
  >
    <div class="radio__content">
      <input
        ref="radioRef"
        v-bind="$attrs"
        class="radio__input outline"
        type="radio"
        tabindex="0"
        :checked="checked"
        :disabled="$props.disabled"
        :name="$props.name"
        @change="toggle"
        @blur="handleBlur"
      />

      <the-icon
        class="radio__icon"
        :art="iconData.art"
        :name="iconData.name"
        @keydown="onKeyDown"
      />

      <slot name="label">
        <span
          class="radio__label"
          :class="$props.size === 'sm' ? 'body-2' : 'body-1'"
          v-html="$props.label"
        />
      </slot>
    </div>
  </label>
</template>

<script setup>
import { computed, getCurrentInstance, ref } from 'vue'

// INIT
const model = defineModel({ type: [String, Number, Boolean, undefined] })
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },

  isInvalid: {
    type: Boolean,
    default: false,
  },

  label: {
    type: String,
    default: null,
  },

  name: {
    type: String,
    required: true,
  },

  size: {
    validator(value) {
      return ['sm', 'md'].includes(value)
    },
    default: 'sm',
  },
})

// DATA
const radioRef = ref()

// COMPUTED
const attrValue = computed(() => {
  return getCurrentInstance().attrs.value
})

const checked = computed(() => {
  return props.modelValue === attrValue.value
})

const classes = computed(() => {
  return {
    [`radio--${props.size}`]: true,
    'radio--selected': checked.value,
    'radio--disabled': props.disabled,
    'radio--error': props.isInvalid,
  }
})

const iconData = computed(() => {
  return {
    art: checked.value ? 'solid' : 'regular',
    name: 'circle',
  }
})

// METHODS
function handleBlur() {
  radioRef.value?.classList.remove('has-outline')
}

function onKeyDown(event) {
  if (event.code === 'Space') {
    toggle()
    event.preventDefault()
  }
}

function toggle() {
  if (!checked.value) {
    model.value = attrValue.value
  }
}
</script>

<style name="mobile" scoped>
.radio {
  --radio-icon-c-background: var(--on-primary);
  --radio-icon-c-border: var(--on-surface);
  --radio-icon-c-background-hover: var(--Interaction-States-hovered-default-b);
  --radio-icon-c-border-hover: var(--on-surface);
  --radio-icon-c-background-active: var(--Interaction-States-pressed-default-c);
  --radio-icon-c-border-active: var(--on-surface);
  --radio-icon-c-outline-hover: var(--Interaction-States-hovered-default-b);
  --radio-icon-c-outline-active: var(--Interaction-States-pressed-default-c);
  --radio-icon-c-label: var(--on-surface);

  cursor: pointer;
  display: inline-block;
  position: relative;

  &--selected {
    --radio-icon-c-background: var(--Interaction-States-enabled-default);
    --radio-icon-c-border: var(--Interaction-States-enabled-default);
    --radio-icon-c-background-hover: var(--Interaction-States-hovered-default-a);
    --radio-icon-c-border-hover: var(--Interaction-States-hovered-default-a);
    --radio-icon-c-background-active: var(--Interaction-States-pressed-default-a);
    --radio-icon-c-border-active: var(--Interaction-States-pressed-default-a);
    --radio-icon-c-outline-hover: var(--Interaction-States-hovered-default-b);
    --radio-icon-c-outline-active: var(--Interaction-States-pressed-default-c);
  }

  &--disabled {
    --radio-icon-c-background: var(--on-primary);
    --radio-icon-c-border: var(--Interaction-States-disabled-default);
    --radio-icon-c-label: var(--Interaction-States-disabled-default-label);

    pointer-events: none;
  }

  &.radio-disabled.radio--selected {
    --radio-icon-c-background: var(--Interaction-States-disabled-default);
    --radio-icon-c-border: var(--Interaction-States-disabled-default);
  }

  &--error {
    --radio-icon-c-background: var(--on-primary);
    --radio-icon-c-border: var(--error);
    --radio-icon-c-background-hover: var(--colors-error-900);
    --radio-icon-c-border-hover: var(--error);
    --radio-icon-c-background-active: var(--colors-error-800);
    --radio-icon-c-border-active: var(--error);
    --radio-icon-c-outline-hover: var(--colors-error-900);
    --radio-icon-c-outline-active: var(--colors-error-800);
  }

  &.radio--error.radio--selected {
    --radio-icon-c-background: var(--error);
    --radio-icon-c-border: var(--error);
  }

  &--sm {
    --radio-icon-scale: 20px;
  }

  &--md {
    --radio-icon-scale: 24px;
  }

  &:hover {
    .radio__icon.icon {
      background-color: var(--radio-icon-c-background-hover);
      color: var(--radio-icon-c-border-hover);
      outline: var(--fixed-spacing-fix-02) solid var(--radio-icon-c-outline-hover);
    }
  }

  &:active {
    .radio__icon.icon {
      background-color: var(--radio-icon-c-background-active);
      color: var(--radio-icon-c-border-active);
      outline: var(--fixed-spacing-fix-02) solid var(--radio-icon-c-outline-active);
    }
  }
}

.radio__content {
  display: flex;
  flex-direction: row;
  column-gap: var(--fixed-spacing-fix-03);
}

.radio__icon.icon {
  font-size: var(--radio-icon-scale);
  color: var(--radio-icon-c-border);
  background-color: var(--radio-icon-c-background);
  border-radius: 100%;
  margin: 2px 0 0;
  transition-property: background-color, outline-width;
  transition-duration: 150ms;
  transition-timing-function: ease-in;
}

.radio__input {
  pointer-events: none;
  position: absolute;
  opacity: 0;
}

.radio__content {
  &:has(.radio__input.has-outline:focus-visible) .radio__icon {
    outline: 2px solid var(--on-surface);
    outline-offset: var(--fixed-spacing-fix-01);
  }
}

.radio__label {
  color: var(--radio-icon-c-label);
}
</style>
