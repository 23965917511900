import { ref } from 'vue'
import { get, hasIn, isArray, isEmpty, isString } from 'lodash'

import locales from '@/components/MedicalSearch/locales'

// GLOBAL DATA
const labels = ref({})
const callbacks = ref({})

export default function useMedicalOffice() {
  // METHODS
  function stringFormat(string, params) {
    const search = /\{([\s\S]+?)\}/g
    params = params || {}

    if (isString(string)) {
      return string.replace('\\n', '\n').replace(search, function (term) {
        let key = term.substring(1).slice(0, -1)
        return params[key] !== undefined ? params[key] : term
      })
    }
    return string
  }

  function labelFormat(label, replacements) {
    if (isArray(label)) {
      return label.map(l => {
        return stringFormat(l, replacements)
      })
    }
    return stringFormat(label, replacements)
  }

  function setOptions(options) {
    if (!isEmpty(options)) {
      callbacks.value = options.callbacks || {}
      setLabels(options.labels)
    }
  }

  function setLabels(optionsLabels) {
    labels.value = optionsLabels || locales
  }

  function hasLabel(id) {
    return hasIn(labels.value, id)
  }

  function getTranslation(id, replacements = '') {
    if (hasLabel(id)) {
      const label = get(labels.value, id, '')
      return labelFormat(label, replacements)
    } else if (id && import.meta.env.DEV) {
      return id
    }
    return ''
  }

  return {
    getTranslation,
    hasLabel,
    setOptions,
  }
}
