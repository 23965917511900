import config from '@/config'

let googleMapsScriptLoaded = false

export function loadGoogleMapsApi() {
  if (googleMapsScriptLoaded) {
    return Promise.resolve()
  }

  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = `https://maps.googleapis.com/maps/api/js?key=${config.googleApiKey}&loading=async&libraries=places&v=weekly&callback=initMap`

    // Execute script after HTML document has been fully parsed
    script.defer = true

    window.initMap = () => {
      googleMapsScriptLoaded = true
      resolve()
    }

    script.onerror = reject

    document.head.appendChild(script)
  })
}

export { googleMapsScriptLoaded }
