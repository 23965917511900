import { get } from 'lodash'

// AVM definition types
export const AVMGroupType = {
  NET_MED: 'NETMED',
  CARE_MED: 'CAREMED',
  MED_BASE: 'MEDBASE',
  PATIENTS: 'PATIENTS',
  NAME: 'NAME',
}

export const MedAVMs = [AVMGroupType.CARE_MED, AVMGroupType.NET_MED]

export const ProductAVMtype = {
  ACSProdukt: AVMGroupType.CARE_MED,
  ANETProdukt: AVMGroupType.NET_MED,
  S2MUL1Produkt: AVMGroupType.MED_BASE,
}

export const getAVMId = (
  productId,
) => ProductAVMtype[productId] || ''

export const getNetworkFromProduct = (product) => {
  if (product) {
    return [get(ProductAVMtype, product.productId, '')]
  }

  return MedAVMs
}

export const transformDoctorForProduct = (doctor) => {
  const {
    firstName,
    lastName,
    zsrNumber,
    email,
    phoneNumber,
    faxNumber,
    medicalOfficeName,
    medicalOfficeStatus,
    medicalOfficeNets,
    ...values
  } = doctor
  const { geo, ...address } = values

  return {
    firstName,
    lastName,
    zsrNumber,
    email,
    phoneNumber,
    faxNumber,
    medicalOfficeName,
    medicalOfficeStatus,
    medicalOfficeNets,
    address: {
      ...address,
      ...geo,
    },
  }
}
