<template>
  <!-- prettier-ignore -->
  <div class="fabButton">
    <basic-button
      class="button"
      hierarchy="tertiary"
      :class="{
        'is-tiny': hasScrolled,
        'has-gap': scrolledToBottom,
        'hide': isVirtualKeyboardShown
      }"
      @click="$emit('click')"
    >
      <the-icon
        art="light"
        :name="icon"
      />
    </basic-button>
  </div>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { debounce } from 'lodash'

import useBrowser from '@/hooks/useBrowser'

import { BasicButton } from '@/components/Basic'

// HOOKS
const { isSmallOnlyViewport } = useBrowser()

// INIT
defineEmits(['click'])
const props = defineProps({
  hasBottomGap: {
    type: Boolean,
    default: false,
  },
  isToggleActive: {
    type: Boolean,
    default: false,
  },
})

// DATA
const hasScrolled = ref(false)
const isVirtualKeyboardShown = ref(false)
const scrollTop = ref(0)
const scrolledToBottom = ref(false)

// COMPUTED
const icon = computed(() => {
  return props.isToggleActive && !isSmallOnlyViewport.value ? 'xmark' : 'user-headset'
})

const isTouchEnabled = computed(() => {
  // @TODO check removal of legacy code
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
})

const windowHeight = computed(() => {
  return window.innerHeight || (document.documentElement || document.body).clientHeight
})

// METHODS
const debouncedScrolledToBottomHandler = debounce(handleScrolledToBottom, 50)

function handleFocusIn(event) {
  if (hasKeyboard(event.target)) {
    isVirtualKeyboardShown.value = true
  }
}

function handleFocusOut() {
  isVirtualKeyboardShown.value = false
}

function handleScroll() {
  hasScrolled.value = true
}

function handleScrolledToBottom() {
  if (!isSmallOnlyViewport.value) {
    scrolledToBottom.value = false
  } else {
    scrollTop.value =
      window.pageYOffset || (document.documentElement || document.body?.parentNode || document.body).scrollTop
    scrolledToBottom.value = scrollTop.value > windowHeight.value
  }
}

function hasKeyboard(target) {
  return (
    isTouchEnabled.value &&
    target.tagName === 'INPUT' &&
    ['TEXT', 'NUMBER', 'PASSWORD'].includes(target.type.toUpperCase())
  )
}

// WATCHERS
watch(isSmallOnlyViewport, value => {
  if (props.hasBottomGap) {
    handleScrolledToBottom()
  }
  if (!value) {
    window.removeEventListener('focusin', handleFocusIn)
    window.removeEventListener('focusout', handleFocusOut)
    isVirtualKeyboardShown.value = false
  } else {
    window.addEventListener('focusin', handleFocusIn)
    window.addEventListener('focusout', handleFocusOut)
  }
})

// LIFECYCLE HOOKS
onMounted(() => {
  window.addEventListener('scroll', handleScroll, { once: true })
  if (props.hasBottomGap) {
    window.addEventListener('scroll', debouncedScrolledToBottomHandler)
  }
  if (isSmallOnlyViewport.value) {
    window.addEventListener('focusin', handleFocusIn)
    window.addEventListener('focusout', handleFocusOut)
  }
})
onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll, { once: true })
  if (props.hasBottomGap) {
    window.removeEventListener('scroll', debouncedScrolledToBottomHandler)
  }
  if (isSmallOnlyViewport.value) {
    window.removeEventListener('focusin', handleFocusIn)
    window.removeEventListener('focusout', handleFocusOut)
  }
})
</script>

<style scoped>
.fabButton {
  .button {
    position: fixed;
    bottom: 24px;
    right: 24px;
    width: 50px;
    min-width: auto;
    height: 50px;
    border: none;
    background-color: var(--on-secondary);
    box-shadow:
      0 6px 6px 0 rgba(0, 0, 0, 0.24),
      0 0 6px 0 rgba(0, 0, 0, 0.12);

    &:deep(.button__inner) {
      padding: 0 12px;
      font-size: 24px;
    }
  }
}
</style>
