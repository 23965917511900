import { pickBy } from 'lodash'

import { CUSTOM_EVENT_SURVEY_TRIGGER } from '@/config/events'
import { SURVEY_TOUCHPOINT_SUBMIT } from '@/config/constants'

import basketStore from '@/store/basket'
import partnerStore from '@/store/partner'
import useApplication from '@/hooks/useApplication'

export default function useSurvey() {
  // HOOKS
  const { dispatchCustomEvent } = useApplication()

  // METHODS
  function triggerSurvey(touchPoint = SURVEY_TOUCHPOINT_SUBMIT) {
    // FLEX-203 no survey for collective
    if (!partnerStore.isCollective.value) {
      // only send vepaNr if non default

      const { basketId, language } = basketStore.basket

      const payload = pickBy(
        {
          basketId,
          language,
          touchPoint,
          vepaNr: btoa(partnerStore.basketDistributionPartnerNumber.value),
        },
        x => !!x
      )

      dispatchCustomEvent(CUSTOM_EVENT_SURVEY_TRIGGER, payload)
    }
  }

  return {
    triggerSurvey,
  }
}
