<template>
  <!-- prettier-ignore -->
  <span
    class="price"
    :class="{'price--loading': !hasPrice}"
  >
    <template v-if="isValidPrice($props.price)">
      <span
        v-if="$props.prefix"
        class="price__prefix"
        :class="$props.prefixClass"
        v-text="$props.prefix"
      />
      <span
        ref="priceValueRef"
        class="price__value"
        :class="$props.priceClass"
      />
      <span
        v-if="$props.suffix"
        class="price__suffix"
        :class="$props.suffixClass"
        v-text="$props.suffix"
      />
      <span
        v-if="$props.additional"
        class="price__additional"
        v-text="$props.additional"
      />
    </template>
    <template v-else>
      <span
        class="price__unavailable"
        v-text="$t('product.unavailable')"
      />
    </template>
  </span>
</template>

<script setup>
import { computed, ref, watchEffect } from 'vue'

import { CountUp } from 'countup.js'

// INIT
const props = defineProps({
  additional: {
    type: String,
    default: null,
  },
  decimals: {
    type: Number,
    default: 2,
  },
  prefix: {
    type: String,
    default: '',
  },
  prefixClass: {
    type: String,
    default: 'body-2',
  },
  price: {
    type: [Number, String],
    default: null,
  },
  priceClass: {
    type: String,
    default: '',
  },
  showZeroValue: {
    type: Boolean,
    default: false,
  },
  suffix: {
    type: String,
    default: null,
  },
  suffixClass: {
    type: String,
    default: 'body-2',
  },
})

// DATA
let installed = ref(false)
const priceValueRef = ref(null)
const selector = ref(null)

// COMPUTED
const hasPrice = computed(() => {
  return isValidPrice(props.price)
})

// METHODS
function install() {
  if (!priceValueRef.value || (priceValueRef.value === 0 && props.showZeroValue) || installed.value) return
  installed.value = true

  const el = priceValueRef.value
  const price = isValidPrice(props.price) ? props.price : 0
  const options = {
    decimalPlaces: props.decimals,
    duration: 0.5,
  }

  selector.value = new CountUp(el, price, options)
  selector.value.start()
}

function isValidPrice(value) {
  return value !== undefined && value !== null
}

function updateSelector(value) {
  if (!installed.value) return
  selector.value.update(isValidPrice(value) ? value : 0)
}

// LIFECYCLE HOOKS
watchEffect(() => {
  if (isValidPrice(props.price)) {
    if (!installed.value) {
      install()
    } else {
      updateSelector(props.price)
    }
  } else {
    installed.value = false
  }
})
</script>

<style scoped>
.price {
  display: inline-block;
  white-space: nowrap;

  &--loading {
    .price__value {
      opacity: 0.1;
    }
  }

  &__value {
    transition: opacity 0.5s ease-in-out;
  }

  &__prefix {
    padding-right: var(--fixed-spacing-fix-02);
  }

  &__suffix {
    padding-left: var(--fixed-spacing-fix-02);
  }

  &__prefix,
  &__suffix {
    display: inline-block;
  }

  &__additional {
    display: block;
  }

  &__unavailable {
    color: var(--c-secondary-neutral-3);
  }
}
</style>
