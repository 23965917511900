export const ChatUtil = {
  getAccidentId(productId) {
    switch (productId) {
      case 'PE04Produkt':
        return 'PE04Unfall'
      case 'SSPB01Produkt':
      case 'SSHB01Produkt':
        return 'SAN_Unfall'
      case 'Z04Produkt':
        return 'Z04Unfall'
      default:
        return 'A00Unfall'
    }
  },

  getAccidentOption(id, value) {
    const suffix = value ? 'mit_Unfall' : 'ohne_Unfall'
    switch (id) {
      case 'PE04Unfall':
        return `PE04${suffix}`
      case 'SAN_Unfall':
        return `SAN_${suffix}`
      case 'Z04Unfall':
        return `Z04${suffix}`
      default:
        return `A00${suffix}`
    }
  },

  getFranchiseId(productId) {
    switch (productId) {
      case 'PKTSProdukt':
      case 'PKISProdukt':
      case 'PUISProdukt':
      case 'PUTSProdukt':
        return `${productId}_Versicherungssumme`
      case 'AB404Produkt':
        return 'AB404Variante'
      case 'SSPB01Produkt':
      case 'SSHB01Produkt':
        return 'SAN_Franchise'
      case 'Z04Produkt':
        return 'Z04Franchise'
      default:
        return 'A05Franchise'
    }
  },

  getFranchiseOption(id, value, isAdult) {
    switch (id) {
      case 'A05Franchise':
        return `05JF_${value}${isAdult ? 'E' : 'K'}`
      case 'AB404Variante':
        return `AB404Leistpausch${value}`
      case 'SAN_Franchise':
        return `SAN_JF_${value}`
      case 'Z04Franchise':
        return `Z04JF_${value}`
      default:
        return value
    }
  },

  getChatProductCriteria(person, product, price) {
    const criteria = []
    if (price?.value) {
      const id = this.getFranchiseId(product.productId)
      const selectedOption = this.getFranchiseOption(id, price.value, person.age >= 18)
      criteria.push({ id, selectedOption })
    }
    if (price?.toggle) {
      const id = this.getAccidentId(product.productId)
      const selectedOption = this.getAccidentOption(id, price.toggle)
      criteria.push({ id, selectedOption })
    }
    return criteria
  }
}
