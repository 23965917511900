<template>
  <!-- prettier-ignore -->
  <basic-modal
    id="confirmationDialog"
    ref="confirmationDialogRef"
    type="transactional"
    :modal-title="data.title"

    :primary-label="data.confirmText"
    :secondary-label="data.cancelText"

    @click-primary="confirm"
    @click-secondary="cancel"

    @close="cancel"
  >
    <div
      class="body-2"
      v-html="data.label"
    />
  </basic-modal>
</template>

<script setup>
import { onBeforeMount, onBeforeUnmount, reactive, ref } from 'vue'

import events$ from '@/services/Events'

import useI18n from '@/hooks/useI18n'

import { BasicModal } from '@/components/Basic'

import { EVENT_CONFIRMATION_MODAL_OPEN } from '@/config/events'

// HOOKS
const { t } = useI18n()

// DATA
const data = reactive({
  cancelText: null,
  confirmText: null,
  label: null,
  reject: null,
  resolve: null,
  severity: null,
  show: false,
  title: null,
})
const confirmationDialogRef = ref()

// METHODS
function cancel() {
  const rejected = data.reject()

  confirmationDialogRef.value.close()

  return rejected
}

function confirm() {
  const resolved = data.resolve()

  confirmationDialogRef.value.close()

  return resolved
}

function doOpenEvent(payload) {
  const { message, buttons, callbacks } = payload

  data.cancelText = buttons?.cancelText || t('form.cancel')
  data.confirmText = buttons?.confirmText || t('form.ok')

  data.label = message?.label
  data.title = message?.title
  data.severity = message?.severity
  data.resolve = callbacks?.resolve
  data.reject = callbacks?.reject

  confirmationDialogRef.value.open()
}

// LIFECYCLE HOOKS
onBeforeMount(() => {
  events$.on(EVENT_CONFIRMATION_MODAL_OPEN, doOpenEvent)
})
onBeforeUnmount(() => {
  events$.off(EVENT_CONFIRMATION_MODAL_OPEN, doOpenEvent)
})
</script>

<style scoped>
.confirmation__message {
  padding: 20px;
}
</style>
