<template>
  <basic-product-price
    class="product__price lead-1"
    :product="$props.product"
    :suffix="$t('product.currency')"
  />

  <div
    class="h3"
    v-text="$t(`content.products.${$props.productId}.name`)"
  />

  <div class="product__options">
    <div class="product__options">
      <div
        v-for="option in productOptions"
        :key="option.label"
        class="product__option"
      >
        <span
          class="title-2"
          v-text="option.label"
        />
        <span
          v-if="option.value"
          class="body-2"
          v-text="option.value"
        />
      </div>
    </div>

    <div class="option__doctor">
      <span
        class="title-2"
        v-text="$t('product.doctor.titleShort')"
      />
      <span
        class="body-2"
        v-text="$props.doctor"
      />
    </div>
  </div>

  <div v-html="$t(`content.products.${$props.productId}.description`)" />

  <template v-if="!$props.disableBullets">
    <ul class="product__bullets">
      <li
        v-for="(bullet, key) in $t(`content.products.${$props.productId}.bulletPoints`)"
        :key="key"
        v-text="bullet"
      />
    </ul>
  </template>

  <div class="product__message">
    <template
      v-for="(message, index) in messages"
      :key="`message-${index}`"
    >
      <basic-message
        :actions="message.actions"
        :closable="message.closeable"
        :content="message.content"
        :severity="message.severity"
      />
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import usePerson from '@/hooks/usePerson'
import usePersonSpecificProduct from '@/hooks/usePersonSpecificProduct'
import useProduct from '@/hooks/useProduct'
import useProductState from '@/hooks/useProductState'

import { BasicMessage, BasicProductPrice } from '@/components/Basic'

// HOOKS
const { composeOptions, getCategoryIdFromProduct } = useProduct()
const { getProductMessages } = usePersonSpecificProduct()
const { state } = useProductState()
const { getPerson } = usePerson()

// INIT
// @note: no attrs are used in this component, only props
defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  disableBullets: {
    type: Boolean,
    default: false,
  },

  doctor: {
    type: String,
    default: null,
  },

  modalRef: {
    type: Object,
    default: () => {},
  },

  personId: {
    type: String,
    required: true,
  },

  product: {
    type: Object,
    required: true,
  },

  productId: {
    type: String,
    required: true,
  },

  isSelected: {
    type: Boolean,
    default: false,
  },
})

// COMPUTED
const hasCalculationWarnings = computed(() => {
  return props.product.calculationWarnings?.length > 0
})

const messages = computed(() => {
  return getProductMessages({
    categoryId: props.categoryId,
    hasCalculationWarnings: hasCalculationWarnings.value,
    modalRef: props.modalRef,
    personId: props.personId,
    product: props.product,
    productState: productState.value,
  })
})

const productOptions = computed(() => {
  const categoryId = getCategoryIdFromProduct(props.productId)
  return composeOptions(Object.assign({}, props.product, { productId: props.productId }), props.groupId, categoryId)
})

const productState = computed(() => {
  const person = getPerson(props.personId)
  const partnerNumber = person.partnerNumber
  return state(partnerNumber, props.productId)
})
</script>

<style scoped>
.product__options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: var(--fixed-spacing-fix-06);
  margin: 0 0 var(--spacing-san-spacing-02) 0;
}

.product__option {
  display: flex;
  flex-direction: row;
  column-gap: var(--fixed-spacing-fix-02);
}

.product__bullets {
  margin: var(--fixed-spacing-fix-04) 0;
  padding: 0 0 0 var(--spacing-san-spacing-06);
}

.product__message {
  display: flex;
  flex-direction: column;
  gap: var(--fixed-spacing-fix-04);
}

.option__doctor {
  display: flex;
  flex-direction: row;
  column-gap: var(--fixed-spacing-fix-02);
  flex-wrap: wrap;
}
</style>
