<template>
  <div
    id="map"
    ref="mapRef"
  />
</template>

<script setup>
import { computed, createApp, onMounted, ref, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { loadGoogleMapsApi, googleMapsScriptLoaded } from '../utils/main'

import {
  googleDefaultOptions,
  googleIconStates,
  SANITAS_MARKER,
  ZURICH_CENTER,
} from '@/components/MedicalSearch/config/constants'
import switzerlandPolygon from '@/components/MedicalSearch/data/switzerland.json'

import {
  createMarker,
  createMarkerIcon,
  createSanitasMarker,
  findMarkerById,
  remapResults,
} from '@/components/MedicalSearch/utils/marker'
import { createPosition, createSwissBoundingBox, isWithinSwissBounds } from '@/components/MedicalSearch/utils/map'

import InfoContent from '@/components/MedicalSearch/components/InfoContent'

import useMapSearch from '@/hooks/useMapSearch'

// HOOKS
const { hoveredId, selectedId, zoom, onZoom } = useMapSearch()

// INIT
const emit = defineEmits(['update', 'loaded', 'reset', 'markerContentHover', 'markerContentLeave', 'select', 'submit'])

const props = defineProps({
  zoomControl: {
    type: Boolean,
    default: true,
  },
  results: {
    type: Array,
    default: () => [],
  },
  location: {
    type: [Object, null],
    required: true,
  },
  product: {
    type: [Object, Boolean],
    default: false,
  },
})

// DATA
const uid = uuidv4()
const mapRef = ref(null)
const mapBounds = ref(null)
const markerLibrary = ref(null)
const infoWindow = ref(null)
let googleMarkers = []
let map
let mappedResults = []
let sanitasMarker

// COMPUTED
const center = computed(() => {
  return props.location ? createPosition(props.location) : ZURICH_CENTER
})

const googleOptions = computed(() => {
  return {
    ...googleDefaultOptions,
    mapId: uid,
    center: center.value,
    zoom: zoom.value,
    zoomControl: props.zoomControl,
  }
})

const selectedOffice = computed(() => {
  return findMarkerById(mappedResults, hoveredId.value || selectedId.value)
})

const sanitasMarkerVisible = computed(() => {
  return zoom.value >= SANITAS_MARKER.minZoom
})

// METHODS
async function initMap() {
  const { Map } = await google.maps.importLibrary('maps')
  return new Map(document.getElementById('map'), googleOptions.value)
}

/**
 * Creates markers for the map depending on the given offices.
 * Adds click listener and marker content mouseover and mouseout event listeners
 */
function drawMarkers() {
  mappedResults.forEach(options => {
    let marker = createMarker(map, options, markerLibrary.value)

    // Adds marker itself click listener
    marker.addListener('click', () => {
      emit('select', options)
    })

    marker.content.addEventListener('mouseover', () => {
      emit('markerContentHover', options.zsrNumber)
    })

    marker.content.addEventListener('mouseout', () => {
      emit('markerContentLeave')
    })

    googleMarkers.push(marker)
  })

  mapRef.value = map
}

function onHoveredIdChange(hoveredMarkerId) {
  if (!hoveredMarkerId && !selectedId.value) {
    hideInfoWindow()
  }

  // RESET HOVERED
  mappedResults
    .filter(entry => entry.state === googleIconStates.HOVER)
    .map(entry => {
      entry.state = googleIconStates.NORMAL
      const markerIcon = createMarkerIcon(entry)
      const marker = googleMarkers.find(_marker => _marker.title === entry.zsrNumber)
      if (marker) {
        marker.content.src = markerIcon.url
      }
      return entry
    })

  // SET HOVERED
  mappedResults
    .filter(entry => entry.zsrNumber === hoveredMarkerId)
    .map(entry => {
      if (entry.state !== googleIconStates.ACTIVE) {
        entry.state = googleIconStates.HOVER
      }
      const markerIcon = createMarkerIcon(entry)
      const marker = googleMarkers.find(_marker => _marker.title === hoveredMarkerId)
      if (marker) {
        marker.content.src = markerIcon.url
        showInfoWindow(marker, hoveredMarkerId)
      }
      return entry
    })
}

function onSelectedIdChange(selectedMarkerId) {
  // RESET SELECTED
  mappedResults
    .filter(entry => entry.state === googleIconStates.ACTIVE)
    .map(entry => {
      entry.state = entry.zsrNumber === hoveredId.value ? googleIconStates.HOVER : googleIconStates.NORMAL
      const markerIcon = createMarkerIcon(entry)
      const marker = googleMarkers.find(_marker => _marker.title === entry.zsrNumber)
      if (marker) {
        marker.content.src = markerIcon.url
      }
      return entry
    })

  // SET ACTIVE
  mappedResults
    .filter(entry => entry.zsrNumber === selectedMarkerId)
    .map(entry => {
      entry.state = googleIconStates.ACTIVE
      const markerIcon = createMarkerIcon(entry)
      const marker = googleMarkers.find(_marker => _marker.title === selectedMarkerId)
      if (marker) {
        marker.content.src = markerIcon.url
        showInfoWindow(marker, selectedMarkerId)
      }
      return entry
    })
}

function initMapListeners(_map) {
  _map.addListener('tilesloaded', () => {
    handleTilesLoadedEvent(_map)
  })
  _map.addListener('bounds_changed', () => {
    handleBoundsEvent(_map.getBounds())
  })
  _map.addListener('zoom_changed', () => {
    handleZoomEvent(_map.getZoom())
  })
}

/**
 * Gets triggered every time the map is updated (e.g. when user zooms, drags map)
 */
function handleTilesLoadedEvent(_map) {
  if (isWithinSwissBounds(mapBounds.value)) {
    emit('update', mapBounds.value)
  } else {
    emit('reset')
    panToSwiss(_map)
  }
}

/**
 * Gets triggered when bounds of maps are changing (e.g when user zooms or drags map)
 */
function handleBoundsEvent(bounds) {
  if (bounds !== undefined) {
    mapBounds.value = bounds
  }
}

/**
 * Set Marker info window
 */
function showInfoWindow(marker, zsrNumber) {
  if (selectedOffice.value) {
    const app = createApp(InfoContent, {
      active: selectedId.value === zsrNumber,
      office: selectedOffice.value,
      product: props.product,
    })
    const content = document.createElement('div')
    app.mount(content)

    const button = content.querySelector('.button')
    if (button) {
      button.addEventListener('click', () => {
        emit('submit')
      })
    }

    infoWindow.value.setContent(content)
    infoWindow.value.open(map, marker)
    infoWindow.value.addListener('domready', () => {
      infoWindow.value.setOptions({ pixelOffset: new google.maps.Size(0, -5) })
    })
  }
}

/**
 * Hide Marker info window
 */
function hideInfoWindow() {
  infoWindow.value.close()
}

/**
 * Handles zoom event on the map
 */
function handleZoomEvent(newZoom) {
  if (zoom.value !== newZoom) {
    onZoom(newZoom)
  }
}

function panToSwiss(_map) {
  const swissBounds = createSwissBoundingBox()
  _map.panToBounds(swissBounds)
}

function toggleSanitasMarker(visible) {
  sanitasMarker.map = visible ? map : null
}

function initMapPolygon(_map) {
  let polygon = new google.maps.Polygon({
    paths: switzerlandPolygon,
    strokeColor: '#5BAC26',
    strokeOpacity: 1,
    strokeWeight: 1,
    fillOpacity: 0.2,
  })
  polygon.setMap(_map)
}

onMounted(async () => {
  if (!googleMapsScriptLoaded) {
    await loadGoogleMapsApi()
  }
  map = await initMap()

  infoWindow.value = new google.maps.InfoWindow({
    minWidth: 320,
    maxWidth: 320,
    disableAutoPan: true,
  })

  // Draw Markers
  markerLibrary.value = await google.maps.importLibrary('marker')
  mappedResults = remapResults(props.results, hoveredId.value, selectedId.value)
  drawMarkers()

  if (map) {
    sanitasMarker = createSanitasMarker(map, markerLibrary.value)
    toggleSanitasMarker(sanitasMarkerVisible.value)
  }

  if (mapRef.value) {
    initMapListeners(mapRef.value)
    initMapPolygon(mapRef.value)
    mapRef.value.panTo(center.value)
    emit('loaded')
  }
})

watch(
  () => props.results,
  newResults => {
    if (markerLibrary.value) {
      while (googleMarkers.length) {
        googleMarkers.pop().setMap(null)
      }
      googleMarkers = []
      mappedResults = remapResults(newResults, hoveredId.value, selectedId.value)
      drawMarkers()
    }
  },
  { deep: true }
)

watch(hoveredId, value => {
  onHoveredIdChange(value)
})

watch(selectedId, value => {
  onSelectedIdChange(value)
})

watch(
  () => sanitasMarkerVisible.value,
  value => {
    toggleSanitasMarker(value)
  }
)

watch(center, value => {
  map.setCenter(value)
})
</script>

<style>
#map {
  height: 96vh;
  width: 100%;
  border-radius: var(--border-radius-res-l) var(--border-radius-res-l) 0 0;
}

@media (--v-large) {
  #map {
    border-radius: 0 var(--border-radius-res-l) 0 0;
  }
}

:root {
  .gm-style-iw-chr {
    display: none !important;
  }

  .gm-style .gm-style-iw-c {
    padding: var(--spacing-san-spacing-06) !important;
  }

  .gm-style-iw-d {
    overflow: auto !important;
    padding: 0 !important;
  }
}
</style>
