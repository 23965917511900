<template>
  <basic-modal
    id="suggestionModal"
    ref="suggestionModalRef"
    type="transactional"
    :modal-title="$t('product.suggestion.title', { savings })"
    :primary-label="$t('product.suggestion.btn.yes')"
    :secondary-label="$t('product.suggestion.btn.no')"
    @click-primary="addProduct(toProduct)"
    @click-secondary="addProduct(fromProduct)"
  >
    <div
      class="body-2-expanded"
      v-text="$t('product.suggestion.subtitle')"
    />

    <div class="products">
      <div class="products__product">
        <div
          class="title-2"
          v-text="$t('product.suggestion.selected')"
        />

        <replace-product
          v-if="fromProduct"
          :doctor="doctorName"
          :modal-ref="suggestionModalRef"
          :person-id="personId"
          :product-id="fromProduct.productId"
          :product="fromProduct"
        />
      </div>

      <div class="products__product products__product--selected">
        <div
          class="title-2"
          v-text="$t('product.suggestion.savings')"
        />

        <replace-product
          v-if="toProduct"
          :doctor="doctorName"
          :modal-ref="suggestionModalRef"
          :person-id="personId"
          :product-id="toProduct.productId"
          :product="toProduct"
        />
      </div>
    </div>
  </basic-modal>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'

import { loading$, events$ } from '@/services'
import { getDoctorName } from '@/utils/Doctor'

import useProductPersons from '@/hooks/useProductPersons'

import { BasicModal } from '@/components/Basic'
import ReplaceProduct from '@/components/Product/Replace/ReplaceProduct'

import { EVENT_PRODUCT, EVENT_SHOW_PRODUCT_SUGGESTION } from '@/config/events'

// HOOKS
const { addProductWithDoctor } = useProductPersons()

// DATA
const suggestionModalRef = ref()

const categoryId = ref(null)
const fromProduct = ref(null)
const toProduct = ref(null)
const doctor = ref(null)
const personId = ref(null)
const source = ref(null)

// COMPUTED
const doctorName = computed(() => {
  return doctor.value ? getDoctorName(doctor.value) : false
})

const savings = computed(() => {
  return 0
})

// METHODS
function cancel() {
  suggestionModalRef.value.close()

  categoryId.value = null
  fromProduct.value = null
  toProduct.value = null
  doctor.value = null
  personId.value = null
  source.value = null
}

async function addProduct(product) {
  loading$.start()

  try {
    await addProductWithDoctor({
      categoryId: categoryId.value,
      doctor: doctor.value,
      personId: personId.value,
      productId: product.productId,
      source: source.value,
      skipCheck: true,
    })

    cancel()

    loading$.end()
  } catch (error) {
    console.error(error)
    loading$.failed()
  }
}

// LIFECYCLE HOOKS
onMounted(() => {
  events$.on(EVENT_SHOW_PRODUCT_SUGGESTION, data => {
    categoryId.value = data.categoryId
    fromProduct.value = data.fromProduct
    toProduct.value = data.toProduct
    doctor.value = data.doctor
    personId.value = data.personId
    source.value = data.source

    events$.emit(EVENT_PRODUCT.SUGGESTION_DISPLAYED)
    suggestionModalRef.value.open()
  })
})
</script>

<style scoped>
.products {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-san-spacing-06);
  margin: var(--spacing-san-spacing-06) 0 0 0;

  .products__product {
    margin: var(--spacing-san-spacing-03) 0 0 0;
    border: 1px solid var(--outline-variant);
    border-radius: var(--radius-border-radius-03);
    padding: var(--fixed-spacing-fix-06) var(--dotcom-responsive-spacing-res-xs) var(--fixed-spacing-fix-07)
      var(--dotcom-responsive-spacing-res-xs);

    &--selected {
      border-color: var(--surface-ext-b-low);
      background-color: var(--surface-ext-b-low);
      padding: var(--fixed-spacing-fix-06) var(--dotcom-responsive-spacing-res-xs) var(--fixed-spacing-fix-07)
        var(--dotcom-responsive-spacing-res-xs);
      border-radius: var(--radius-border-radius-03);
    }
  }
}

@media (--v-medium) {
}

@media (--v-large) {
  .products {
    flex-direction: row;
  }
}

@media (--v-wide) {
}

@media (--v-ultra) {
}

@media (--v-max) {
}
</style>
