<template>
  <div class="filters-group">
    <div class="filters-group__content">
      <basic-text-input
        v-model="doctorName"
        name="doctorName"
        :label="getTranslation('form.filters.name')"
      />
      <basic-checkbox
        v-model="formData.receivePatients"
        name="receivePatients"
        :label="getTranslation('form.filters.patients')"
        :v="v.receivePatients"
      />
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, watch } from 'vue'

import { sameAs } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

import useMedicalOffice from '@/hooks/useMedicalOffice'

import { BasicCheckbox, BasicTextInput } from '@/components/Basic/index'

// HOOKS
const { getTranslation } = useMedicalOffice()

// INIT
const emit = defineEmits(['filter'])

// DATA
const currentFilters = ref([])
const doctorName = ref(null)

const formData = reactive({
  receivePatients: false,
})

const rules = {
  receivePatients: { checked: sameAs(true) },
}

const v = useVuelidate(rules, formData)

// METHODS
function handleInputChange(value) {
  if (!value) {
    currentFilters.value = currentFilters.value.filter(f => f.name !== 'NAME')
    emit('filter', currentFilters.value)
  } else if (value.length >= 3) {
    const inputIndex = currentFilters.value.findIndex(f => f.name === 'NAME')
    if (inputIndex === -1) {
      currentFilters.value.push({ name: 'NAME', value: value })
    } else {
      currentFilters.value[inputIndex].value = value
    }
    emit('filter', currentFilters.value)
  }
}

function handleCheckboxChange(isChecked) {
  if (isChecked) {
    currentFilters.value.push({ name: 'PATIENTS', value: true })
  } else {
    currentFilters.value = currentFilters.value.filter(f => f.name !== 'PATIENTS')
  }
  emit('filter', currentFilters.value)
}

// WATCHERS
watch(doctorName, value => {
    handleInputChange(value)
  },
  { immediate: true }
)

watch(
  () => formData.receivePatients,
  value => {
    handleCheckboxChange(value)
  },
  { immediate: true }
)
</script>

<style scoped></style>
