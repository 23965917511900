import basketStore from '@/store/basket'
import partnerStore from '@/store/partner'
import sessionStore from '@/store/session'
import useBasket from '@/hooks/useBasket'
import useI18n from '@/hooks/useI18n'

import { createDistributionPartners } from '@/helpers/Partner'

import { CHANNEL, ROUTE_NAME, SALES_PARTNER_TYPE } from '@/config/constants'

const isTestEnv = import.meta.env.VITE_ENV_KEY !== 'PRD'

// HOOKS
const { createDefaultBasket } = useBasket()
const { browserLanguage } = useI18n()

function getNextRoute(to) {
  const nextRoute = {
    name: ROUTE_NAME.PERSONS,
    replace: true,
  }

  if (to.query.cmpID) {
    nextRoute.query = {
      cmpID: to.query.cmpID,
    }
  }

  return nextRoute
}

/**
 * Add all partner routes
 *
 * partner
 * PARTNER (Smile-Direct): #/partner/346448
 * PRIVATE_INSURER (Allianz): #/partner/317826
 * BROKER: #/partner/337981
 * MOBILE: #/partner/365231
 *
 * collective-partner
 * FH Schweiz: #/K003910
 * Credit Suisse: #/K003000
 * Siemens Kader: #/K003777
 * Finanzverwaltung Basel Stadt: #/K004070
 */
export default [
  {
    name: ROUTE_NAME.PARTNER,
    path: '/partner/:distributionPartnerNumber([0-9]{6})',
    meta: {
      disableUnloadConfirmation: true,
      secured: false,
    },
    async beforeEnter(to, _from, next) {
      const distributionPartnerNumber = to.params.distributionPartnerNumber

      try {
        await sessionStore.clearSession()
      } catch {
        // No session yet, just proceed
      }
      basketStore.emptyBasket()

      const payload = await createDefaultBasket({
        channel: CHANNEL.PARTNERDVP,
        distributionPartners: createDistributionPartners(distributionPartnerNumber),
        partnerNumber: distributionPartnerNumber,
        language: browserLanguage?.toUpperCase(),
      })

      const partnerData = await partnerStore.install({
        contractStartDate: payload.contractStartDate,
        language: browserLanguage?.toUpperCase(),
        collectiveNumber: null,
        partnerNumber: null,
        distributionPartnerNumber,
      })

      const validPartnerTypes = [
        SALES_PARTNER_TYPE.DEFAULT,
        SALES_PARTNER_TYPE.DEFAULT_SANITAS,
        SALES_PARTNER_TYPE.PARTNER,
      ]

      /**
       * @NOTE: only default, default_sanitas and partner are allowed to use this entry
       */
      if (validPartnerTypes.includes(partnerData.distributionPartnerInfo.salesPartnerType) || isTestEnv) {
        if (partnerData.distributionPartnerInfo.masterTreaty) {
          payload.masterTreaty = partnerData.distributionPartnerInfo.masterTreaty
        }

        if (
          [SALES_PARTNER_TYPE.BROKER, SALES_PARTNER_TYPE.PRIVATE_INSURER].includes(
            partnerData.distributionPartnerInfo.salesPartnerType
          )
        ) {
          payload.salesPartnerEmail = partnerData.distributionPartnerInfo.email
        }

        await basketStore.updateOvpBasket(payload)

        next(getNextRoute(to))
      } else {
        next(getNextRoute(to))
      }
    },
  },
  {
    name: ROUTE_NAME.COLLECTIVE,
    path: '/:collectiveNumber(K[0-9]{6})',
    meta: {
      disableUnloadConfirmation: true,
      secured: false,
    },
    async beforeEnter(to, _from, next) {
      const collectiveNumber = to.params.collectiveNumber

      try {
        await sessionStore.clearSession()
      } catch {
        // No session yet, just proceed
      }
      basketStore.emptyBasket()

      const payload = await createDefaultBasket({
        channel: CHANNEL.PARTNERDVP,
        language: browserLanguage?.toUpperCase(),
      })
      // const config = await install(payload, collectiveNumber)

      const partnerData = await partnerStore.install({
        contractStartDate: payload.contractStartDate,
        language: browserLanguage?.toUpperCase(),
        collectiveNumber,
        partnerNumber: null,
        distributionPartnerNumber: null,
      })

      const cConfig = partnerData.collectiveContractConfig

      payload.distributionPartners = createDistributionPartners(cConfig.distributionPartnerSanitas)

      // ['infoText'] not an allowed key for the collective-property
      delete cConfig.infoText

      // @note: isn't here only channel and contractNumber needed?
      payload.collective = cConfig

      if (cConfig.masterTreaty) {
        payload.masterTreaty = cConfig.masterTreaty
      }

      await basketStore.updateOvpBasket(payload)

      next(getNextRoute(to))
    },
  },
]
