<template>
  <div class="contact">
    <div
      class="title-3"
      v-text="$props.titleLabel"
    />

    <span
      v-for="(time, index) in contactInfo?.phone?.times"
      :key="index"
      class="body-3"
      v-text="`${time.days} ${time.hours}`"
    />

    <basic-link
      v-if="contactInfo?.phone?.enabled"
      data-track-as="fab-appointment"
      :disabled="!contactInfo.phone.active"
      :data-track-text="contactInfo.phone.nr"
      :label="contactInfo.phone.nr"
      :href="`tel:${contactInfo.phone.nr}`"
    >
      <template #leading>
        <the-icon
          art="solid"
          name="phone"
        />
      </template>
    </basic-link>

    <basic-link
      v-if="contactInfo?.chat"
      data-track-as="fab-appointment"
      :disabled="!contactInfo.chat.active"
      :data-track-text="'chat'"
      :label="$t('fab.entries.assistance.links.chat.name')"
      @click="handleLinkClick({ action: { name: 'openChat' } })"
    >
      <template #leading>
        <the-icon
          art="solid"
          name="comments"
        />
      </template>
    </basic-link>

    <basic-link
      v-if="contactInfo?.email"
      data-track-as="fab-appointment"
      :disabled="!contactInfo.email"
      :data-track-text="'email'"
      :href="`mailto:${contactInfo.email[selectedLanguage?.toLowerCase()]}`"
      :label="contactInfo.email[selectedLanguage?.toLowerCase()]"
    >
      <template #leading>
        <the-icon
          class="entry__icon"
          art="solid"
          name="envelope"
        />
      </template>
    </basic-link>
  </div>
</template>

<script setup>
import useContact from '@/hooks/useContact'
import useI18n from '@/hooks/useI18n'

import { BasicLink } from '@/components/Basic'

// HOOKS
const { contactInfo } = useContact()
const { selectedLanguage } = useI18n()

// INIT
const emit = defineEmits(['action'])
defineProps({
  titleLabel: {
    type: String,
    required: true,
  },
})

// METHODS
function handleLinkClick(link) {
  emit('action', link.action)
}
</script>

<style scoped>
.contact {
  display: flex;
  flex-direction: column;
  row-gap: var(--fixed-spacing-fix-02);
}
</style>
