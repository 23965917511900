<template>
  <div class="missing-tan-modal">
    <div class="modal-content">
      <div class="modal-description">
        <div
          class="body-2-expanded"
          v-text="$t('signature.verification.modal.textDesc')"
        />
        <div
          class="body-2-expanded"
          v-text="$t('signature.verification.modal.textSubDesc')"
        />
      </div>
      <div class="modal-actions">
        <div class="contact-info-data">
          <div
            class="title-3"
            v-text="$props.contactInfo.phone.name"
          />
          <span
            v-for="(time, index) in $props.contactInfo.phone.times"
            :key="index"
            class="body-3"
            v-text="`${time.days} ${time.hours}`"
          />
        </div>
        <div class="links">
          <template v-if="$props.contactInfo?.phone?.enabled">
            <basic-link
              data-track-as="fab-appointment"
              :disabled="!$props.contactInfo.phone.active"
              :data-track-text="$props.contactInfo.phone.nr"
              :label="$props.contactInfo.phone.nr"
              :href="`tel:${$props.contactInfo.phone.nr}`"
            >
              <template #leading>
                <the-icon
                  art="solid"
                  name="phone"
                />
              </template>
            </basic-link>
          </template>
          <template v-if="$props.contactInfo.chat?.enabled">
            <basic-link
              data-track-as="fab-appointment"
              :disabled="!$props.contactInfo.chat.active"
              :data-track-text="'chat'"
              :label="$t('fab.entries.assistance.links.chat.name')"
              @click="handleLinkClick({ action: { name: 'openChat' } })"
            >
              <template #leading>
                <the-icon
                  art="solid"
                  name="comments"
                />
              </template>
            </basic-link>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { EVENT_CHAT } from '@/config/events'
import { events$ } from '@/services'

import { BasicLink } from '@/components/Basic'

// INIT
defineProps({
  contactInfo: {
    type: Object,
    required: true,
  },
})

// METHODS
function handleLinkClick() {
  events$.emit(EVENT_CHAT.OPEN)
}
</script>

<style scoped>
.modal-content {
  padding: var(--spacing-san-spacing-04) 0 var(--spacing-san-spacing-06) 0;
}
.modal-description {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-san-spacing-04);
  padding-bottom: var(--spacing-san-spacing-04);
}

.modal-actions {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-san-spacing-02);

  .links {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-san-spacing-02);
  }
}
</style>
