import { computed } from 'vue'
import { cloneDeep } from 'lodash'

import { events$ } from '@/services'

import { addDoctorToBasket, addDoctorToProduct, appliesToProduct, removeUnusedDoctors } from '@/utils/Doctor'
import { updatePersonInBasket } from '@/utils/Person'
import { updatePriceOptions } from '@/utils/Product'
import { ReactivityUtil } from '@/utils/Reactivity'

import basketStore from '@/store/basket'
import usePerson from '@/hooks/usePerson'
import usePersonDetails from '@/hooks/usePersonDetails'
import usePersonSpecificProduct from '@/hooks/usePersonSpecificProduct'
import useProduct from '@/hooks/useProduct'

import { EVENT_PRODUCT, EVENT_SHOW_PRODUCT_SUGGESTION } from '@/config/events'

export default function useProductPersons() {
  /**
   *  @file useProductPersons contains logic for products involving all persons
   */

  // COMPUTED
  const hasSelectedProducts = computed(() => {
    const { persons } = usePerson()
    if (persons.value.length === 0) return false

    return (
      persons.value.filter(person => {
        return (
          Object.keys(person.products.products).filter(productId => person.products.products[productId].selected)
            .length > 0
        )
      }).length > 0
    )
  })

  const selectedCategories = computed(() => {
    const { persons } = usePerson()
    const { hasPersonOnlyKVG, hasPersonOnlyVVG } = usePersonSpecificProduct()

    if (persons.value.length > 0) {
      return persons.value.reduce((result, p) => {
        result[p.personId] = {
          isKVGOnly: hasPersonOnlyKVG(p.personId),
          isVVGOnly: hasPersonOnlyVVG(p.personId),
        }
        return result
      }, {})
    }
    return []
  })

  /**
   *  selectedCategoriesByPerson
   *  @todo same as {@link selectedCategories}, but using personsWithDetails instead of persons
   *
   */
  const selectedCategoriesByPerson = computed(() => {
    const { personsWithDetails } = usePersonDetails()
    const { hasPersonOnlyKVG, hasPersonOnlyVVG } = usePersonSpecificProduct()
    return personsWithDetails.value.reduce((result, person) => {
      result[person.personId] = {
        isKVGOnly: hasPersonOnlyKVG(person.personId),
        isVVGOnly: hasPersonOnlyVVG(person.personId),
      }
      return result
    }, {})
  })

  // METHODS
  function addProductDoctorToBasket({ personId, productId, doctor, basket = cloneDeep(basketStore.basket) }) {
    const { mutateProductsInBasketForPerson } = usePersonSpecificProduct()
    let pBasket, updatedBasket

    pBasket = mutateProductsInBasketForPerson(personId, productId, { basket })
    pBasket = addDoctorToProduct(pBasket, productId, doctor)
    updatedBasket = updatePersonInBasket(basket, personId, pBasket)
    updatedBasket = removeUnusedDoctors(updatedBasket)
    updatedBasket = addDoctorToBasket(updatedBasket, doctor)
    return Promise.resolve(updatedBasket)
  }

  async function addProductWithDoctor({ categoryId, doctor, personId, productId, source, skipCheck = false }) {
    const fromProductId = 'ACSProdukt'
    const toProductId = 'ANETProdukt'

    const _addProduct = async _basket => {
      _basket = await basketStore.updateOvpBasket(_basket)

      events$.emit(EVENT_PRODUCT.ADDED, {
        basket: _basket,
        categoryId: categoryId,
        personId: personId,
        productId: productId,
        source: source,
      })

      return _basket
    }

    let basket = await addProductDoctorToBasket({
      personId,
      productId,
      doctor,
    })

    if (productId !== fromProductId || skipCheck) {
      return _addProduct(basket)
    } else if (appliesToProduct(toProductId, doctor)) {
      basket = await basketStore.updateOvpBasket(basket, { simulate: true })
      const pBasket = basket.persons.find(p => p.personId === personId)

      const fromProduct = ReactivityUtil.clone(pBasket.products.products[fromProductId])
      let toProduct = pBasket.products.products[toProductId]

      fromProduct.selected = false

      toProduct.prices = fromProduct.prices
      toProduct.selected = true
      toProduct.doctor = fromProduct.doctor

      basket = await basketStore.updateOvpBasket(basket, { simulate: true })
      toProduct = basket.persons.find(p => p.personId === personId).products.products[toProductId]

      if (fromProduct.prices.find(p => p.selected).price > toProduct.prices.find(p => p.selected).price) {
        events$.emit(EVENT_SHOW_PRODUCT_SUGGESTION, {
          categoryId,
          doctor,
          fromProduct: Object.assign({}, fromProduct, { productId: fromProductId, selected: true }),
          personId,
          source,
          toProduct: Object.assign({}, toProduct, { productId: toProductId }),
        })

        return Promise.resolve()
      } else {
        toProduct.selected = false

        return _addProduct(basket)
      }
    } else {
      return _addProduct(basket)
    }
  }

  function addProductToOvpBasket(personId, productId, options = {}) {
    const { mutateProductsInBasketForPerson } = usePersonSpecificProduct()
    const originalBasket = options.basket ?? cloneDeep(basketStore.basket)
    const product = originalBasket.persons.find(p => p.personId === personId).products.products[productId]
    const doctor = options.doctor || product?.doctor

    if (doctor) {
      return addProductDoctorToBasket({ personId, productId, doctor, basket: originalBasket })
    } else {
      const pBasket = mutateProductsInBasketForPerson(personId, productId, {
        basket: originalBasket,
        mode: options.mode,
      })
      let updatedBasket = updatePersonInBasket(originalBasket, personId, pBasket)
      updatedBasket = removeUnusedDoctors(updatedBasket)
      return updatedBasket
    }
  }

  function removePersonsWithoutProductFromOvpBasket(basket) {
    const { getSelectedProductsOfPerson } = useProduct()
    const immutablePersons = basket.existingCustomer ? basket.persons.filter(p => p.partnerNumber) : []
    // @todo probably should check differently if person has products?
    const personsWithProducts = basket.persons
      .filter(p => !p.partnerNumber)
      .filter(p => getSelectedProductsOfPerson(p.products.products).length > 0)
    const payloadPersons = [...immutablePersons, ...personsWithProducts]

    return basketStore.updateOvpBasket({ persons: payloadPersons })
  }

  function __selectProductOption({ personId, productId, option, basket = cloneDeep(basketStore.basket) }) {
    const pBasket = basket.persons.find(p => p.personId === personId)
    const product = pBasket.products.products[productId]

    product.prices = updatePriceOptions(product.prices, option)
    const updatedBasket = updatePersonInBasket(basket, personId, pBasket)
    return Promise.resolve(updatedBasket)
  }

  /**
   * transferProductToPerson
   * @param {string} sourceId
   * @param {string} targetId
   * @param {string} productId
   * @param {object} options
   * @returns {Promise<Basket>}
   *
   */
  async function transferProductToPerson(sourceId, targetId, productId, options = {}) {
    const { getPerson, persons } = usePerson()
    const __basket = options.basket ?? null
    const mode = options.mode ?? 'REPLACE'
    const doctor = options.doctor ?? null
    const __person = __basket ? persons.value.find(p => p.personId === sourceId) : getPerson(sourceId)
    const product = __person.products.products[productId]
    const option = product.prices.find(p => p.selected)

    const updatedBasket = await addProductToOvpBasket(targetId, productId, { basket: __basket, doctor, mode })
    return await __selectProductOption({ personId: targetId, productId, option, basket: updatedBasket })
  }

  return {
    // COMPUTED
    hasSelectedProducts,
    selectedCategories,
    selectedCategoriesByPerson,

    // METHODS
    addProductDoctorToBasket,
    addProductToOvpBasket,
    addProductWithDoctor,
    removePersonsWithoutProductFromOvpBasket,
    transferProductToPerson,
  }
}
