import { medState } from '@/components/MedicalSearch/config/constants'

export const MedicalOffice = {
  country: '',
  email: '',
  faxNumber: '',
  geo: {},
  houseNumber: '',
  firstName: '',
  id: '',
  name: '',
  lastName: '',
  medicalOfficeNets: [],
  medicalOfficeStatus: {},
  netmedDiscount: '',
  phoneNumber: '',
  street: '',
  town: '',
  zipCode: '',
  zsrNumber: '',
}

export const getMedState = status => {
  switch (status) {
    case medState.ALL:
    case medState.NOBODY:
    case medState.ACCORDING_FAMILY_DOCTOR:
      return null
    case medState.CHILDREN:
      return 'states.children'
    case medState.FAMILYMEMBERS:
      return 'states.familyMembers'
    case medState.SPECIALIST_NOT_ELIGIBLE:
      return 'states.notEligible'
    case medState.UNKNOWN:
    default:
      return 'states.unknown'
  }
}

export const isDoctorAvailable = doctor => {
  const unavailableStates = [medState.NOBODY, medState.ACCORDING_FAMILY_DOCTOR]

  return doctor?.medicalOfficeStatus && !unavailableStates.includes(doctor.medicalOfficeStatus)
}