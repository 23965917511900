import { ref, watch } from 'vue'

import { ChatUtil } from '@/utils/Chat'
import { EVENT_CHAT, EVENT_LANGUAGE, CUSTOM_EVENT_CHAT } from '@/config/events'

import Events from '@/services/Events'

import basketStore from '@/store/basket'
import useApplication from '@/hooks/useApplication'
import usePerson from '@/hooks/usePerson'
import useProduct from '@/hooks/useProduct'
import useI18n from '@/hooks/useI18n'

// GLOBAL DATA
let initialized = false

export default function useChat() {
  // LOCAL DATA
  const chatOpen = ref(false)
  const chatConversation = ref(false)
  const chatTriggered = ref(false)

  // HOOKS
  const { dispatchCustomEvent, pendingHTML } = useApplication()
  const { selectedLanguage, t } = useI18n()
  const { persons } = usePerson()
  const { getCategoryIdFromProduct } = useProduct()

  // METHODS
  function initChat() {
    if (!initialized && import.meta.env.VITE_FEATURE_CHAT) {
      initialized = true

      _initializeChat()
        .then(() => $_chatAddEventDispatcher())
        .then(() => $_chatAddEventListener())
        .then(() => {
          dispatchCustomEvent(CUSTOM_EVENT_CHAT.LANGUAGE, {
            language: selectedLanguage.value,
          })

          if (chatOpen.value) {
            _openChat()
          }
        })
        .finally(_ => {
          Events.emit(EVENT_CHAT.LOADED, initialized)
        })
    }
  }

  function _initializeChat() {
    return (
      pendingHTML('.sanchat-wrapper')
        .then(_ => (initialized = true))
        // ON production we disable the chat if it not loaded
        .catch(_ => (initialized = import.meta.env.DEV))
    )
  }

  function _openChat(options) {
    if (initialized) {
      const eventOptions = options ?? { behavior: 'active' }
      dispatchCustomEvent(CUSTOM_EVENT_CHAT.OPEN, eventOptions)
    }
  }

  function $_chatAddEventDispatcher() {
    Events.on(EVENT_CHAT.OPEN, event => {
      chatOpen.value = true
      _openChat(event)
    })

    Events.on(EVENT_CHAT.NUDGING, () => {
      if (!chatTriggered.value) {
        chatTriggered.value = true
        dispatchCustomEvent(CUSTOM_EVENT_CHAT.TRIGGER_NUDGING)
      }
    })

    Events.on(EVENT_LANGUAGE.LOADED, ({ language }) => {
      dispatchCustomEvent(CUSTOM_EVENT_CHAT.LANGUAGE, { language })
    })

    return Promise.resolve()
  }

  function $_chatAddEventListener() {
    const listenTo = window.addEventListener

    // start sending basket to chat app
    listenTo(CUSTOM_EVENT_CHAT.CONVERSATION_STARTED, () => {
      // send basket to chat app
      dispatchCustomEvent(CUSTOM_EVENT_CHAT.BASKET_UPDATED, _getChatBasket(basketStore.basket))
      chatConversation.value = true
    })

    // emit close event on chat closing
    listenTo(CUSTOM_EVENT_CHAT.CLOSE, () => {
      chatOpen.value = false
      Events.emit(EVENT_CHAT.CLOSE)
    })

    // disable watcher if chat is closed
    listenTo(CUSTOM_EVENT_CHAT.CONVERSATION_ENDED, () => {
      chatConversation.value = false
    })

    return Promise.resolve()
  }

  function _getChatBasket(_basket) {
    return {
      persons: _getChatPersons(),
      basketDetails: {
        location: _basket?.address,
        contractStartDate: _basket?.contractStartDate,
        collectiveContractNumber: _basket?.collective?.contractNumber,
      },
    }
  }

  function _getChatPersons() {
    const _persons = persons.value
    return _persons?.map(person => {
      return {
        firstName: person.personData.firstName,
        dateOfBirth: person.personData.dateOfBirth,
        gender: person.personData.gender,
        personGroup: person?.collectivePersonGroup,
        products: getChatProducts(person),
      }
    })
  }

  function getChatProducts(person) {
    const products = person.products?.products || {}
    return Object.values(products)
      .filter(product => !!product.selected)
      .map(product => {
        const productId = product.productId || product.id
        const categoryId = getCategoryIdFromProduct(productId)
        const price = product.prices.find(productPrice => productPrice.selected)

        return {
          criteria: ChatUtil.getChatProductCriteria(person, product, price),
          group: t(`content.categories.${categoryId}.title`),
          productName: t(`content.products.${productId}.name`),
          price: price.price,
        }
      })
  }

  // WATCHERS
  watch(
    basketStore.basket,
    value => {
      if (chatConversation.value) {
        dispatchCustomEvent(CUSTOM_EVENT_CHAT.BASKET_UPDATED, _getChatBasket(value))
      }
    },
    { deep: true }
  )

  return {
    initChat,
  }
}
