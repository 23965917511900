import { events$ } from '@/services'

import basketStore from '@/store/basket'
import useSurvey from '@/hooks/useSurvey'
import useSync from '@/hooks/useSync'

import { EVENT_OVP } from '@/config/events'
import { ROUTE_INDEX, ROUTE_NAME } from '@/config/constants'

// HOOKS
const { triggerSurvey } = useSurvey()
const { unregisterSync } = useSync()

export default [
  {
    beforeEnter(to, from, next) {
      // case: entry anywhere of a already submitted basket
      if (basketStore.basket.submitted && to.query.forcedRouting) {
        next()

        // case: reload of page
      } else if (from.name === undefined) {
        next({ name: ROUTE_NAME.PERSONS })

        // case: normal entry to route
      } else {
        if (!from.meta.isReentry) {
          triggerSurvey()

          unregisterSync()

          events$.emit(EVENT_OVP.APPLICATION_SUBMITTED, {
            basket: basketStore.basket,
          })
        }
        next()
      }
    },
    component: () => import('@/modules/Configurator/screens/ScreenNextSteps'),
    meta: {
      disableNavigation: true,
      headerDisabled: true,
      index: ROUTE_INDEX.NEXT_STEPS,
      navigation: false,
      secured: false,
    },
    name: ROUTE_NAME.NEXT_STEPS,
    path: '/next-steps',
  },
]
