<template>
  <!-- prettier-ignore -->
  <div
    class="digit__input digit__input__container"
    :class="classes"
  >
    <div
      class="digit__input__wrapper"
    >
      <input
        ref="digitInputRef"
        v-model="model"
        class="digit__input__text outline"
        data-lpignore="true"
        data-1p-ignore
        type="number"
        inputmode="numeric"
        maxlength="1"
        placeholder="0"
        :name="$props.name"
        @focus="handleFocus"
        @blur="handleBlur"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'

// INIT
defineProps({
  name: {
    type: String,
    required: true,
  },

  v: {
    type: Object,
    default: () => {},
  },
})

const emit = defineEmits(['blur', 'clear'])
const model = defineModel({
  type: [String, Number],
  set(value) {
    if (value === '') return null

    return value
  },
})

// DATA
const digitInputRef = ref(null)
const isFocused = ref(false)

// COMPUTED
const classes = computed(() => {
  return {
    'digit__input--focus': isFocused.value,
  }
})

// METHODS
function handleBlur() {
  digitInputRef.value?.classList.remove('has-outline')
  isFocused.value = false
  emit('blur', model.value)
}

function handleFocus() {
  digitInputRef.value.select()
  isFocused.value = true
}

// EPILOGUE
defineExpose({
  digitInputRef,
})
</script>

<style scoped>
.digit__input {
  --dvp-input-c-label-text: var(--on-surface-medium);
  --dvp-input-c-active-indicator: var(--dvp-input-c-default-active-indicator);
  --dvp-input-c-supporting-text: var(--on-surface-medium);
}

.digit__input__text {
  font-size: var(--font-size-regular-xl);
  line-height: var(--line-height-regular-xl);
}

/* Active (focused) input */
.digit__input--focus .digit__input__text {
  box-shadow: inset 0 -2px 0 var(--dvp-input-c-active-indicator);
}

/* Focused input (when navigating with tab) */
.digit__input__container:has(.has-outline) {
  outline: 2px solid var(--dvp-input-c-outline);
  outline-offset: var(--fixed-spacing-fix-01);
  border-radius: var(--fixed-border-radius-fix-02);
}

.digit__input__container {
  height: var(--spacing-san-spacing-12);
  background-color: var(--dvp-input-c-background);
  border-radius: var(--Input-fields-input-border-radius);
  padding: 0 var(--fixed-spacing-fix-04);

  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.digit__input__wrapper {
  font-size: var(--font-size-regular-md);
}

.digit__input__text {
  width: var(--fixed-spacing-fix-04);
  height: 48px;
  padding: 1px 2px;
  align-items: center;
  text-align: left;

  border: none;
  border-radius: 0;
  background-color: inherit;
  color: var(--on-surface);
  box-shadow: inset 0 -1px 0 var(--dvp-input-c-active-indicator);

  transition-property: opacity;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--on-surface-medium);
  }
}

/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}
</style>
