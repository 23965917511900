import { computed, reactive } from 'vue'

// GLOBAL DATA
const config = reactive({})

export default function useABConfig() {
  // COMPUTED
  const abConfig = computed(() => {
    return config
  })

  return {
    abConfig,
  }
}
