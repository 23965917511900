import { events$, loading$ } from '@/services'
import { getRouteAfterReentry } from '@/helpers/Route'

import basketAPI from '@/api/basket'
import basketStore from '@/store/basket'
import sessionStore from '@/store/session'

import { EVENT_SESSION, EVENT_TRACKING } from '@/config/events'
import { REENTRY_PREFIX_SP, REENTRY_PREFIX_DVP, ROUTE_NAME } from '@/config/constants'

async function loadBasket(to, _from, next) {
  const token = to.params.token

  try {
    loading$.start()

    const response = await basketAPI.sendSMSTan(token)

    let nextPayload
    if (response.mobile) {
      const payload = {
        mobile: response.mobile,
        distributionPartners: response.distributionPartners,
      }
      sessionStore.setSession(payload)
      basketStore.persistBasket(payload)
    } else {
      const basket = await basketStore.getBasket({ token })
      events$.emit(EVENT_TRACKING.ENTERED)
      events$.emit(EVENT_SESSION.UPDATE)

      const name = getRouteAfterReentry(basket)
      nextPayload = { name, query: to.query }
    }

    loading$.end()
    next(nextPayload)
  } catch {
    loading$.failed()
    try {
      await sessionStore.clearSession()
    } catch {
      // There was no basket-session yet and the sendSMSTan-call failed early
      // Just need to catch the error and do nothing so that we can navigate to the persons-screen
    }
    next({ name: ROUTE_NAME.PERSONS })
  }
}

const routeOptions = {
  beforeEnter: loadBasket,
  component: () => import('@/modules/Configurator/screens/ScreenReEntry'),
  meta: {
    disableUnloadConfirmation: true,
    navigation: false,
    secured: false,
    allowNavigationToNextSteps: true,
    isReentry: true,
  },
  props: true,
}

export default [
  {
    ...routeOptions,
    name: ROUTE_NAME.REENTRY,
    path: `/:token(${REENTRY_PREFIX_DVP}[A0-Z9]{8})`,
  },
  {
    ...routeOptions,
    name: ROUTE_NAME.REENTRY_OA,
    path: `/:token(${REENTRY_PREFIX_SP}[A0-Z9]{8})`,
  },
]
