<template>
  <div
    class="input"
    :class="classes"
  >
    <basic-message
      v-if="isTestEnv"
      class="input__test-hint"
      severity="warning"
      title-label="OTP will be sent to the channels in Slack <a target='_blank' href='slack://channel?team=T19FP6GTF&id=C037W6U89B4'>#dev-sms-tan</a> and Teams <a target='_blank' href='https://teams.microsoft.com/l/channel/19%3Aba43d4c541aa4c51bf4ada971397f4f1%40thread.tacv2/dvp-tokens?groupId=0d0d8e1e-e52d-4c69-947f-6c46b871f87a&tenantId=a95da8fa-882c-4804-aeb4-81388d204ea8'>#tokens</a>"
    />
    <div class="code__content">
      <div class="code__inputs">
        <div
          ref="codeElementsRef"
          class="input__elements"
        >
          <div
            v-for="(nr, index) in props.length"
            :key="nr"
            class="input__element"
          >
            <basic-digit-input
              :key="`digit-${index}`"
              :ref="el => (digitInputRef[index] = el)"
              v-model="values[index]"
              :name="`${props.name}-${nr}`"
              @input="event => fill(event, index)"
              @keydown="event => handleKeydown(event, index)"
            />
          </div>
        </div>
        <div
          class="code__inputs__hint caption-regular"
          v-text="autoHint"
        />
      </div>
    </div>
    <slot />
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'

import useI18n from '@/hooks/useI18n'

import BasicDigitInput from '@/components/Basic/DigitInput'
import BasicMessage from '@/components/Basic/Message'

// HOOKS
const { t } = useI18n()

// INIT
const model = defineModel({ type: String })
const props = defineProps({
  length: {
    type: Number,
    default: 4,
  },

  name: {
    type: String,
    required: true,
  },

  label: {
    type: String,
    default: '',
  },

  supportingText: {
    type: String,
    default: undefined,
  },

  v: {
    type: Object,
    default: () => {},
  },
})
const isTestEnv = import.meta.env.VITE_ENV_KEY !== 'PRD'

// DATA
const codeElementsRef = ref(null)
const digitInputRef = ref({})
const values = ref({})

// COMPUTED
const autoHint = computed(() => {
  if (isInvalid.value) {
    const error = props.v?.$errors[0]?.$validator
    if (error) return t(`form.error.${error}`)
  }
  return props.supportingText
})

const classes = computed(() => {
  return {
    'input--invalid': isInvalid.value,
  }
})

const isInvalid = computed(() => {
  return props.v?.$dirty && props.v?.$invalid
})

// METHODS
function clear() {
  ;[...Array(props.length).keys()].forEach(index => {
    values.value[index] = null
  })
}

function fill(event, index) {
  if (event.data) {
    values.value[index] = event.data

    const nextEl = codeElementsRef.value.children[index + 1]

    // has follow-up field
    if (nextEl) {
      goto(index + 1)

      // must have been the last one
    } else {
      const input = []
      Object.keys(values.value).forEach(value => input.push(values.value[value]))
      model.value = input.join('')
    }

    // field was cleared
  } else {
    values.value[index] = null
    model.value = null
  }
}

function goto(index) {
  digitInputRef.value[index].digitInputRef.focus()
}

function handleKeydown(event, index) {
  switch (true) {
    case event.shiftKey:
      event.preventDefault()
      break
    case event.code === 'Backspace':
    case event.code === 'Delete':
      if (index > 0) {
        window.setTimeout(() => {
          goto(index - 1)
        }, 50)
      }
      break
    case event.code.substr(0, 5) !== 'Digit' &&
      event.code.substr(0, 6) !== 'Numpad' &&
      event.code !== 'Backspace' && // Not Backspace
      event.code !== 'Tab' && // Not Tab
      event.code !== 'Enter' && // Not Enter (submit action)
      event.code !== 'NumpadEnter' && // Not NumpadEnter (submit action)
      event.code !== 'Delete' && // Not Delete
      event.code !== 'KeyV': // Not 'V' (paste action)
      event.preventDefault()
  }
}

function handlePaste(event) {
  const paste = (event.clipboardData || window.clipboardData).getData('text')
  const splitPaste = paste?.split('')
  event.preventDefault()

  if (paste && paste.length === props.length) {
    ;[...Array(props.length).keys()].forEach(index => {
      values.value[index] = splitPaste[index]
    })
    model.value = paste
  }
}

// LIFECYCLE HOOKS
onMounted(() => {
  clear()

  // register paste-handler on the first input field
  digitInputRef.value[0].digitInputRef.addEventListener('paste', event => handlePaste(event))
})

// WATCHER
watch(model, value => {
  if (!value) clear()
})
</script>

<style scoped>
.code__content {
  display: flex;
  justify-content: center;
}
.code__inputs {
  display: flex;
  flex-direction: column;
  row-gap: var(--fixed-spacing-fix-02);
  margin: 0 0 var(--dotcom-responsive-spacing-res-m) 0;
}

.input__elements {
  display: flex;
  justify-content: center;
  gap: var(--fixed-spacing-fix-04);
}

.input__element {
  width: 44px;
  height: 48px;
}

.code__inputs__hint {
  display: inline-block;
  height: var(--line-height-regular-xs);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.input--invalid {
  .code__inputs__title,
  .code__inputs__hint {
    color: var(--error);
  }
}

.input__test-hint {
  margin: 0 0 var(--dotcom-responsive-spacing-res-l) 0;
}
</style>
