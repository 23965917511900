<template>
  <div class="location-search">
    <div class="location-search__content">
      <div class="location-search__title">
        <span v-text="getTranslation('form.title')" />
      </div>
      <basic-location
        ref="zipChooserRef"
        v-model="formData.location"
        contract-start-date="2021-01-01"
        name="$props.location"
        :v="v.location"
        :label="getTranslation('form.zipCode.title')"
        @update:modelValue="handleLocationUpdate"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, watch } from 'vue'
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

import useMedicalOffice from '@/hooks/useMedicalOffice'
import { BasicLocation } from '@/components/Basic'

// HOOKS
const { getTranslation } = useMedicalOffice()

// INIT
const emit = defineEmits(['blur', 'focus', 'search'])
const props = defineProps({
  location: {
    type: Object,
    default: () => {},
    required: true,
  },
})

// DATA
const formData = reactive({
  location: { ...props.location },
})

// COMPUTED
const isValid = computed(() => {
  return Boolean(formData.location?.zipCode)
})

// METHODS
function handleLocationUpdate() {
  if (isValid.value) {
    emit('search', formData.location)
  }
}

const rules = {
  location: { required },
}

const v = useVuelidate(rules, formData)

// WATCHERS
watch(
  () => props.location,
  location => {
    if (!location) {
      return
    }
    formData.location = location
  }
)

watch(formData.location, () => {
  handleLocationUpdate()
})
</script>

<style scoped>
.location-search {
  height: auto;

  &__title {
    padding: var(--fixed-spacing-fix-04) 0;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-bold-lg);
    letter-spacing: var(--letter-spacing-bold-lg);
    line-height: var(--line-height-bold-lg);
  }
}
</style>
