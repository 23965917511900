<template>
  <!-- prettier-ignore -->
  <div
    ref="el"
    class="results-card"
    :class="classes"
    :data-office-number="$props.office.zsrNumber"
    @click="select"
    @mouseover="handleMouseOver"
    @mouseout="handleMouseOut"
  >
    <info-content
      button-class="appearance-extended"
      :active="$props.active"
      :office="$props.office"
      :product="$props.product"
      @submit="$emit('submit')"
    >
      <template #discountGroup>
        <div
          v-if="discountGroup"
          class="doctor-discount"
        >
          <div v-show="false">
            <div :id="`discountGroup_${$props.product.productId}`">
              <h4 v-text="$t('medicalofficesearch.reductions.title')"/>
              <div v-text="$t('medicalofficesearch.reductions.text')"/>
              <div
                v-for="(icon, type) in reductions"
                :key="icon"
              >
                <div>
                  <the-icon
                    class="signal__icon"
                    art="solid"
                    :name="icon"
                  />
                  {{ $t(`medicalofficesearch.reductions.level.${type}`) }}
                </div>
              </div>
              <br>
              <div v-html="$t('medicalofficesearch.reductions.note')"/>
              <br>
            </div>
          </div>
          <div
            v-if="!isEmpty(discountTooltip)"
            class="doctor-discount__title"
          >
            <span
              v-tooltip="discountTooltip"
              class="doctor-discount__tooltip"
            >
              <span class="doctor-discount__text">{{ $t('product.reduction') }}</span>
              <the-icon
                class="signal__icon"
                art="solid"
                :name="signalIcon"
              />
            </span>
          </div>
        </div>
      </template>

      <template #officeState>
        <!--
        <div v-if="officeState">
          <p
            class="results-card__state"
            v-text="getTranslation(officeState)"
          />
        </div>
        -->
      </template>
    </info-content>
  </div>
</template>

<script setup>
import { computed, nextTick, reactive, ref, watch } from 'vue'
import { isEmpty } from 'lodash'

import InfoContent from '@/components/MedicalSearch/components/InfoContent'

// import { getMedState } from '@/components/MedicalSearch/utils/doctor'
import { AVMGroupType, getAVMId } from '@/components/MedicalSearch/utils/product'

import useMapSearch from '@/hooks/useMapSearch'

// HOOKS
const { setHoveredId, setSelectedId } = useMapSearch()

// INIT
const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
  autoScroll: {
    type: Boolean,
    default: false,
  },
  hover: {
    type: Boolean,
    default: false,
  },
  hoverDelay: {
    type: Number,
    default: 300,
  },
  office: {
    type: Object,
    default: () => undefined,
    required: true,
  },
  product: {
    type: Object,
    default: () => undefined,
  },
})

// DATA
const el = ref(null)
let discountTooltip = reactive({})

let reductions = { highest: 'signal-good', medium: 'signal-fair', lowest: 'signal-weak' }
let timeout

defineEmits(['submit'])

// COMPUTED
const classes = computed(() => {
  return {
    'results-card--active': props.active,
    'results-card--hover': props.hover && !props.active,
  }
})

const discountGroup = computed(() => {
  // discounts don't apply
  if (!props.product?.showDiscountGroup) return false

  const productNetwork = getAVMId(props.product?.productId)

  if (productNetwork === AVMGroupType.NET_MED) {
    const groups = props.office.medicalOfficeNets.filter(
      n => n.avmDefinition === AVMGroupType.NET_MED && n.discountGroup
    )
    return groups && groups.length === 1 ? groups[0] : false
  }
  return false
})

const signalIcon = computed(() => {
  switch (parseInt(discountGroup.value.discountGroup)) {
    case 1:
    default:
      return 'signal-good'

    case 2:
      return 'signal-fair'

    case 3:
      return 'signal-weak'
  }
})

// METHODS
function handleMouseOut() {
  clearTimeout(timeout)
  if (props.hover) {
    setHoveredId()
  }
}

function handleMouseOver() {
  timeout = setTimeout(() => {
    setHoveredId(props.office.zsrNumber)
  }, props.hoverDelay)
}

function select() {
  setSelectedId(props.office.zsrNumber)
}

function getDiscountContent() {
  const __el = document.getElementById(`discountGroup_${props.product.productId}`)
  if (__el) {
    Object.assign(discountTooltip, {
      allowHTML: true,
      content: __el,
    })
  } else {
    window.setTimeout(() => {
      getDiscountContent()
    }, 1000)
  }
}

function scrollIntoView() {
  if (!props.autoScroll || !props.active) {
    return
  }

  nextTick(() => {
    if (!el.value) {
      return
    }

    const offsetTop = el.value.getBoundingClientRect().top
    const offsetBottom = el.value.offsetHeight
    const parentElement = el.value?.parentElement
    const parentNode = parentElement?.parentNode

    if (offsetTop < 0 || offsetTop > parentNode.offsetHeight - offsetBottom) {
      el.value.scrollIntoView({
        behavior: 'instant',
        block: 'nearest',
      })
    }
  })
}

// WATCHERS
watch(() => [props.active], scrollIntoView)
watch(
  () => discountGroup,
  value => {
    if (value) getDiscountContent()
  },
  { immediate: true }
)
</script>

<style scoped>
.results-card {
  display: flex;
  flex-direction: column;
  position: relative;
  border-bottom: 1px solid var(--outline);
  padding: var(--fixed-spacing-fix-04) var(--fixed-spacing-fix-02);
  color: var(--on-surface);

  cursor: pointer;
  user-select: none;

  transition: all 0.25s ease-in-out;
}

.results-card:hover,
.results-card--hover {
  background-color: var(--surface-ext-b-medium);

  span,
  .icon {
    color: var(--on-surface);
  }
}

.results-card--active {
  background-color: var(--surface-ext-b-low);
}

.results-card__state {
  margin-top: 10px;
}
.results-card__icon {
  position: absolute;
  top: 10px;
  right: 20px;
}

.results-card__types {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.results-card__type {
  display: inline-flex;
  margin-top: 5px;
  margin-right: 5px;

  .icon {
    margin-right: 5px;
  }
}
.signal__icon {
  font-size: var(--font-size-bold-sm);
  color: var(--secondary);
}

.doctor-discount__tooltip {
  display: flex;
  flex-direction: row;
  gap: var(--fixed-spacing-fix-02);
}

.doctor-discount__text {
  font-size: var(--font-size-bold-xs);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-bold-sm);
  letter-spacing: var(--letter-spacing-bold-xs);
}

@media (--md) {
  .results-card__icon {
    top: inherit;
    bottom: 10px;
  }
}

@media (--md) {
  .results-card__types {
    height: 100%;
    min-height: 60px;
    justify-content: space-between;
  }

  .results-card__type {
    width: 25px;
    height: 100%;
    align-items: center;
    display: flex;

    &:not(:first-child) {
      margin-left: 20px;
    }
  }
}
</style>
