import { getDay, getHours, getMinutes } from 'date-fns'
const DAYS = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa']

export const ContactUtil = {
  amIOpen(times) {
    const today = new Date()
    const _today = getDay(today)
    const _hours = getHours(today)
    const _min = getMinutes(today)

    return times.some(time => {
      const _start = DAYS.indexOf(time.start)
      const _end = DAYS.indexOf(time.end)

      const _s = time.startTime.split(':')
      const _e = time.endTime.split(':')

      const startHour = parseInt(_s[0])
      const startMin = parseInt(_s[1])
      const endHour = parseInt(_e[0])
      const endMin = parseInt(_e[1])

      return (
        isInDayRange(_today, _start, _end) &&
        isAfterStartTime(_hours, _min, startHour, startMin) &&
        isBeforeCloseTime(_hours, _min, endHour, endMin)
      )
    })
  },
}

/**
 * Checks if today's date is within start-end range (inclusive)
 * @param today
 * @param start
 * @param end
 * @return {boolean}
 */
function isInDayRange(today, start, end) {
  return today >= start && today <= end
}

/**
 * Checks if current time is after start time (inclusive)
 * @param hour
 * @param min
 * @param startHour
 * @param startMin
 * @return {boolean}
 */
function isAfterStartTime(hour, min, startHour, startMin) {
  return hour > startHour || (hour === startHour && min >= startMin)
}

/**
 * Checks if current time is before end time (exclusive)
 * @param hour
 * @param min
 * @param endHour
 * @param endMin
 * @return {boolean}
 */
function isBeforeCloseTime(hour, min, endHour, endMin) {
  return hour < endHour || (hour === endHour && min < endMin)
}
