import {
  MapIcon,
  MapIconActive,
  MapIconHover,
  SanitasLogo,
} from '@/components/MedicalSearch/config/icons'
import { createPosition } from '@/components/MedicalSearch/utils/map'

import { googleIconStates, SANITAS_MARKER } from '@/components/MedicalSearch/config/constants'

export const createMarkerIcon = (options) => {
  const icon = getMarkerIcon(options.state)
  return { url: icon }
}

export const createMarker = (map, options, markerLibrary) => {
  const { geo, state } = options
  const icon = createMarkerIcon({ state })

  const customMarkerContent = document.createElement('img')
  customMarkerContent.src = icon.url
  customMarkerContent.style.width = options?.height || 40
  customMarkerContent.style.height = options?.width || 40

  return new markerLibrary.AdvancedMarkerElement({
    map,
    position: createPosition(geo),
    content: customMarkerContent,
    title: options.zsrNumber
  })
}

/**
 * Creates a custom marker with the Sanitas logo
 */
export const createSanitasMarker = (map, markerLibrary) => {
  const { id } = SANITAS_MARKER
  const options = {
    ...SANITAS_MARKER,
    state: id,
    width: '75px',
    height: '75px',
  }
  return createMarker(map, options, markerLibrary)
}

/**
 * Styles the marker regarding if it's the hovered, the selected or a default marker
 */
export const remapResults = (data, hoveredId, selectedId) => {
  return data.map(entry => {
    const state = markerIconState(entry.zsrNumber, hoveredId, selectedId)
    return  { ...entry, state }
  })
}

/**
 * Searches for the marker object with the given id.
 */
export const findMarkerById = (markers, id) => {
  return markers.find(d => d.zsrNumber === id) || null
}

const markerIconState = (zsrNumber, hovered, selected) => {
  if (zsrNumber === selected) return googleIconStates.ACTIVE
  if (zsrNumber === hovered) return googleIconStates.HOVER
  return googleIconStates.NORMAL
}

const getMarkerIcon = state => {
  switch (state) {
    case googleIconStates.ACTIVE:
      return MapIconActive
    case googleIconStates.HOVER:
      return MapIconHover
    case googleIconStates.NORMAL:
      return MapIcon
    case SANITAS_MARKER.id:
      return SanitasLogo
    default:
      return state || ''
  }
}
