import { events$, loading$ } from '@/services'

import { logError, logInfo } from '@/helpers/Logger'

import { STORAGE_KEY } from '@/config/constants'
import { EVENT_SESSION } from '@/config/events'

import { DateTimeHelper } from '@/helpers/DateTime'

import basketStore from '@/store/basket'
import sessionStore from '@/store/session'
import useBasket from '@/hooks/useBasket'

// GLOBAL DATA
const consoleColor = 'color: green'

export default function useSession() {
  // METHODS
  // eslint-disable-next-line sonarjs/cognitive-complexity
  async function autostart(payload = {}, forcedOverwrite = false) {
    const { createDefaultBasket } = useBasket()

    // session is active, just return data
    // can only be executed, if autostart is triggered manually
    if (sessionStore.session.active && forcedOverwrite === false) {
      return $_getFromStorage()
    } else {
      const defaultBasket = await createDefaultBasket(payload)

      const dataFromStorage = $_getFromStorage()
      let basket

      if (!dataFromStorage.hot && dataFromStorage.updateDate && window.ineum) {
        window.ineum('reportError', 'basket_id_already_available')
      }

      // resume session from localStorage.
      // @NOTE ((dataFromStorage.hot || dataFromStorage.updateDate) is a workaround, to fix issue with basket_id_already_available)
      if (dataFromStorage && (dataFromStorage.hot || dataFromStorage.updateDate) && !forcedOverwrite) {
        const { updateLanguage } = useBasket()

        // try to resume. if it fails, restart
        try {
          basket = await resumeSession(dataFromStorage)
        } catch (error) {
          return autostart({}, true)
        }

        Object.assign(basket, payload)

        await updateLanguage({ language: basket.language })
        await sessionStore.setSession(basket)
        await basketStore.persistBasket(basket)
      } else {
        // create local basket-session
        if (!forcedOverwrite) {
          basket = Object.assign({}, defaultBasket, dataFromStorage)

          await sessionStore.setSession(basket, { hot: false })
          await basketStore.persistBasket(basket)

          // create 'live' basket-session
        } else {
          basket = await basketStore.createOvpBasket(defaultBasket)
        }
      }

      return basket
    }
  }

  function $_getFromStorage() {
    const item = window.localStorage.getItem(STORAGE_KEY)
    let value
    try {
      value = item && JSON.parse(item)
    } catch (e) {
      logError(['Failed to get session from localstorage\n', e, item])
    }

    const hasExpired = (value && value.eol && !DateTimeHelper.isInFuture(new Date(value.eol))) || !value?.basketId

    if (!value || hasExpired) {
      return false
    } else {
      return { ...value }
    }
  }

  /**
   * try resuming a session, either from localstorage (hot:false), or from the backend(hot:true)
   * @note: at this step, we don't have the basket from the be yet, so you need to check the state from the localStorage
   */
  async function resumeSession(payload) {
    const { basketId, jwToken } = payload

    let _basket
    if (!payload.hot) {
      _basket = payload
    } else {
      _basket = await basketStore.getBasket({ basketId, jwToken }, true)
    }

    if (!_basket) {
      loading$.failed()
      throw new Error(`failed to load basket ${basketId}`)
    }

    logInfo(['%cRESUME SESSION', consoleColor, basketId])
    events$.emit(EVENT_SESSION.RESUME, basketId)
    return _basket
  }

  return {
    autostart,
    resumeSession,
  }
}
