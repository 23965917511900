<template>
  <!-- prettier-ignore -->
  <basic-modal
    id="saveNudgeModal"
    ref="saveNudgeModalRef"
    type="transactional"
    :modal-title="$t('basket.saveNudge.saveForLater')"
    @click-secondary="close"
    @click-primary="formRef.submit()"
  >
    <basic-form
      ref="formRef"
      class="screen-form form"
      name="save_nudge"
      tracking-disabled
      :validator="v"
      @cancel="close"
      @submit="submit"
    >
      <div
        class="body-2"
        v-text="$t('basket.modal.basketScreenModalText')"
      />

      <basic-text-input
        v-model="formData.email"
        class="form__input"
        name="email"
        required
        type="email"
        :placeholder="$t('person.email')"
        :label="$t('person.email')"
        :v="v.email"
      />

    </basic-form>

    <div class="policy">
      <div
        class="title-3"
        v-text="$t('basket.modal.basketScreenDataProtection')"
      />

      <div
        class="body-3"
        v-html="$t('basket.modal.basketScreenDataProtectionText')"
      />
    </div>
  </basic-modal>
</template>

<script setup>
import { onMounted, reactive, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import useVuelidate from '@vuelidate/core'

import { email } from '@/validations/components'
import { loading$, events$ } from '@/services'

import basketStore from '@/store/basket'
import useApplication from '@/hooks/useApplication'
import useI18n from '@/hooks/useI18n'
import useProductPersons from '@/hooks/useProductPersons'
import useSnackbar from '@/hooks/useSnackbar'

import { BasicForm, BasicModal, BasicTextInput } from '@/components/Basic'

import { ROUTE_INDEX, SAVE_NUDGE_DELAY } from '@/config/constants'
import { EVENT_BASKET, EVENT_PRODUCT, EVENT_OVP, SOURCE } from '@/config/events'

// HOOKS
const { isWww } = useApplication()
const { t } = useI18n()
const { hasSelectedProducts } = useProductPersons()
const { addNotification, removeNotification } = useSnackbar()
const route = !isWww.value ? useRoute() : false

// DATA
const formData = reactive({
  email: undefined,
})
const saveNudgeModalRef = ref()
const formRef = ref()
const isSaveNudgeEnabled = parseInt(import.meta.env.VITE_FEATURE_SAVE_NUDGE) === 1
const snackId = ref()

// VALIDATIONS
const v = useVuelidate(
  {
    email,
  },
  formData,
  { $lazy: true }
)

// METHODS
function close(trackClose = true) {
  saveNudgeModalRef.value.close()

  if (snackId.value) removeNotification(snackId.value)
  if (trackClose) events$.emit(EVENT_OVP.NUDGE_CLOSE)
}

function registerListener() {
  if (isSaveNudgeEnabled && !basketStore.basket.email && !hasSelectedProducts.value && !route?.meta?.aide) {
    // @note: nudging is different on dotcom, than in dvp
    if (isWww.value) {
      events$.once(EVENT_PRODUCT.ADDED, showNudgingWww)
    } else {
      events$.once(EVENT_PRODUCT.ADDED, showNudging)
    }
  }
}

function showNudgingWww() {
  if (isSaveNudgeEnabled && !basketStore.basket.email) {
    window.setTimeout(() => {
      snackId.value = addNotification({
        text: t('basket.modal.basketSavingTitle'),
        action: {
          label: t('form.save'),
          callback: () => {
            saveNudgeModalRef.value.open()
          },
        },
      }).id
    }, SAVE_NUDGE_DELAY)
  }
}

function showNudging() {
  if (isSaveNudgeEnabled && !basketStore.basket.email) {
    window.setTimeout(() => {
      // don't show on basket and later screens
      if (route.meta.index >= ROUTE_INDEX.BASKET) {
        return
      }

      snackId.value = addNotification({
        text: t('basket.modal.basketSavingTitle'),
        action: {
          label: t('form.save'),
          callback: () => {
            saveNudgeModalRef.value.open()
          },
        },
      }).id
    }, SAVE_NUDGE_DELAY)
  }
}

async function submit() {
  const offerUpdate = !!basketStore.basket.oaBasketId

  loading$.start()

  const payload = Object.assign({}, formData)
  try {
    const __basket = await basketStore.updateOvpBasket(payload, {}, { emailType: 'OFFER_REENTRY_WITHOUT_EGATE' })

    close(false)
    events$.emit(EVENT_BASKET.SAVED, {
      basket: __basket,
      source: SOURCE.SAVE_NUDGE,
      update: offerUpdate,
    })

    addNotification({
      text: t('basket.modal.basketSaved'),
    })
    loading$.end()
  } catch {
    loading$.failed()
  }
}

// WATCHERS

// LIFECYCLE HOOKS
onMounted(() => {
  registerListener()

  if (!isWww.value) {
    const stopWatching = watch(route, it => {
      if (it.meta.index >= ROUTE_INDEX.BASKET) {
        close(false)
        stopWatching()
      }
    })
  }
})
</script>

<style scoped>
.screen-form {
  display: flex;
  flex-direction: column;
  gap: var(--fixed-spacing-fix-04);
}

.policy {
  display: flex;
  flex-direction: column;
  gap: var(--fixed-spacing-fix-02);
  padding-bottom: var(--dotcom-responsive-spacing-res-xl);

  &:deep(a) {
    color: var(--Interaction-States-enabled-default);
    border-bottom: 1px solid var(--Interaction-States-enabled-default);
    font-weight: bold;
    cursor: pointer;
    transition-property: color, border-color;
    transition-duration: 150ms;
    transition-timing-function: ease-in;

    &:focus-visible {
      outline: 2px solid var(--on-surface);
      outline-offset: var(--fixed-spacing-fix-01);
      border-radius: var(--fixed-border-radius-fix-02);
    }

    &:hover {
      color: var(--Interaction-States-hovered-default-a);
      border-color: var(--Interaction-States-hovered-default-a);
    }
  }
}
</style>
