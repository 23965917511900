<template>
  <div
    ref="contextMenuRef"
    class="contextMenu"
    :class="{ 'contextMenu--fullWidth': $props.hasFullWidthOnMobile }"
  >
    <div
      ref="triggerRef"
      class="trigger"
    >
      <slot name="trigger"></slot>
    </div>

    <Transition
      appear
      name="pop"
    >
      <template v-if="modelValue">
        <div
          v-click-outside="{ handler: handleClickOutside, excluded: excludedSelectors }"
          class="menu"
        >
          <slot name="content"></slot>
        </div>
      </template>
    </Transition>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'

import vClickOutside from '@/directives/ClickOutside'

// INIT
const modelValue = defineModel({ type: Boolean })
const excludedSelectors = ['.close-button', '.trigger-btn', '.link']

defineProps({
  hasFullWidthOnMobile: {
    type: Boolean,
    default: false,
  },
})

// DATA
const contextMenuRef = ref(null)
const triggerRef = ref(null)
const topMenuPosition = ref(0)

// METHODS
function handleClickOutside() {
  window.setTimeout(() => {
    modelValue.value = false
  }, 250)
}

watch(modelValue, val => {
  if (val) {
    topMenuPosition.value = `${triggerRef.value.getBoundingClientRect().height}px`
  }
})
</script>

<style name="animations" scoped>
@keyframes pop {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.pop-enter-active {
  animation: pop 0.3s;
}

.pop-leave-active {
  animation: pop 0.3s reverse;
}
</style>

<style scoped>
.contextMenu {
  position: relative;

  :deep(.close-button) {
    float: right;
  }
}

.menu {
  position: absolute;
  top: calc(v-bind(topMenuPosition) + var(--fixed-spacing-fix-01));
  right: 0;
  border-radius: var(--fixed-spacing-fix-02);
  z-index: var(--dvp-stack-level-popup-menu);
  background-color: var(--surface);
  overflow: hidden;
  box-shadow: var(--elevation-level-3);
}

.contextMenu--fullWidth .menu {
  border-radius: var(--border-radius-res-l) var(--border-radius-res-l) var(--fixed-border-radius-fix-00)
    var(--fixed-border-radius-fix-00);

  position: fixed;
  top: initial;
  left: 0;
  bottom: 0;
  height: 96dvh;
}

@media (--v-medium) {
  .contextMenu--fullWidth .menu {
    position: absolute;
    left: initial;
    bottom: initial;
    top: calc(v-bind(topMenuPosition) + var(--fixed-spacing-fix-01));
    height: initial;
    border-radius: var(--fixed-spacing-fix-02);
  }
}
</style>
