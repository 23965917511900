import { getNextRoute } from '@/helpers/Route'

import basketStore from '@/store/basket'
import productStructureStore from '@/store/productStructure'
import useBasket from '@/hooks/useBasket'
import usePerson from '@/hooks/usePerson'
import useStatus from '@/hooks/useStatus'

import { CATEGORY_TYPE, ROUTE_INDEX, ROUTE_NAME } from '@/config/constants'

function beforeEnter(to, _from, next) {
  const { isStaticBasket } = useBasket()
  const { isPersonalDataValid } = useStatus()
  const { filterPersonsToValidate } = usePerson()

  if (basketStore.basket.persons.length === 0) {
    next({
      name: ROUTE_NAME.PERSONS,
    })
  } else {
    productStructureStore.ready().then(() => {
      if (!isPersonalDataValid(filterPersonsToValidate(basketStore.basket)) || isStaticBasket.value) {
        next({ name: getNextRoute(basketStore.basket) })
      } else if (!to.params.personId) {
        next({
          name: ROUTE_NAME.OFFERS,
          params: {
            personId: filterPersonsToValidate(basketStore.basket)[0].personId,
          },
        })
      } else {
        next()
      }
    })
  }
}

export default [
  {
    beforeEnter: beforeEnter,
    name: ROUTE_NAME.OFFERS_ALT,
    path: '/products',
  },
  {
    beforeEnter: beforeEnter,
    children: [
      {
        component: () => import('@/modules/Configurator/screens/Products/ScreenGroups'),
        meta: {
          index: ROUTE_INDEX.OFFERS,
        },
        name: 'groups-collective',
        path: ':categoryId(SCPC_ADDITIONAL)',
        props: route => ({
          categoryId: route.params.categoryId,
          groupId: route.params.groupId || '',
          personId: route.params.personId,
          hasFilter: true,
          isGrouped: true,
        }),
      },
      {
        component: () => import('@/modules/Configurator/screens/Products/ScreenGroups'),
        meta: {
          index: ROUTE_INDEX.OFFERS,
        },
        name: 'groups',
        path: ':categoryId',
        props: route => ({
          categoryId: route.params.categoryId,
          groupId: route.params.groupId || '',
          personId: route.params.personId,
          hasFilter: route.params.categoryId === CATEGORY_TYPE.KVG,
          isGrouped: route.params.categoryId !== CATEGORY_TYPE.KVG,
        }),
      },
    ],
    component: () => import('@/modules/Configurator/screens/Products/ScreenProduct'),
    meta: {
      index: ROUTE_INDEX.OFFERS,
    },
    name: ROUTE_NAME.OFFERS,
    path: '/products/:personId',
    props: true,
  },
  {
    beforeEnter: beforeEnter,
    component: () => import('@/modules/Configurator/screens/Products/ScreenIncentive'),
    meta: {
      index: ROUTE_INDEX.OFFERS_INCENTIVE,
    },
    name: ROUTE_NAME.OFFERS_INCENTIVE,
    path: '/products-incentive/:personId',
    props: true,
  },
]
