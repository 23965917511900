<template>
  <!-- prettier-ignore -->
  <basic-modal
    id="medical-office-search-modal"
    ref="modalRef"
    size="maxi"
    :has-gradient="false"
    :modal-actions="false"
  >
    <medical-office-maps
      :contract-start-date="contractStartDate"
      :options="searchOptions"
      :product="selectedProduct"
      @close="closeModal"
      @submit="updateDoctor"
    />
  </basic-modal>
</template>

<script setup>
import { computed, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue'
import BasicModal from '@/components/Basic/Modal'

import { get } from 'lodash'

import { events$ } from '@/services'

import masterDataAPI from '@/api/masterData'
import useBasket from '@/hooks/useBasket'
import useI18n from '@/hooks/useI18n'
import useMapSearch from '@/hooks/useMapSearch'
import useMedicalOffice from '@/hooks/useMedicalOffice'
import usePersonSpecificProduct from '@/hooks/usePersonSpecificProduct'

import MedicalOfficeMaps from '@/components/MedicalSearch/components/MedicalOfficeMaps'
import { productAVMtypes, ZOOM_DEFAULT } from '@/components/MedicalSearch/config/constants'
import { transformDoctorForProduct } from '@/components/MedicalSearch/utils/product'

import { EVENT_MEDICAL_OFFICE_MODAL_OPEN, SOURCE } from '@/config/events'

// HOOKS
const { contractData } = useBasket()
const { t } = useI18n()
const { onZoom } = useMapSearch()

// INIT
defineEmits(['closed'])

// DATA
const modalRef = ref()
const closingCallback = ref(() => {})
const isProductSwapDisallowed = ref(false)
const selectedPersonId = ref(null)
const selectedProduct = ref({})
const selectedSource = ref(SOURCE.CONFIGURATOR)

// COMPUTED
const contractStartDate = computed(() => {
  return contractData.value.contractStartDate
})

const initialZoom = computed(() => {
  return labelSuffix.value === 'medbase' ? 11 : ZOOM_DEFAULT
})

const labelSuffix = computed(() => {
  const type = get(productAVMtypes, selectedProduct.value.productId, '')
  return type ? type.toLowerCase() : 'default'
})

/**
 * Will initialize the labels for the medical-office-search itself
 * @return {Object}
 */
const labels = computed(() => {
  return {
    sanitas: {
      postAddress: t('application.sanitas.postAddress'),
      phoneNumber: t('application.sanitas.phoneNumber'),
    },
    form: {
      input: t('medicalofficesearch.form.input')[labelSuffix.value],
      title: t('medicalofficesearch.form.title')[labelSuffix.value],
      submit: t('medicalofficesearch.form.submit')[labelSuffix.value],
      zipCode: {
        title: t('person.plz'),
        placeholder: t('location.placeholder'),
      },
      errors: {
        keyRequired: t('form.error.required'),
        noResults: t('location.noresults'),
        serviceNotAvailable: t('error.NETWORK'),
      },
      filters: {
        title: t('medicalofficesearch.filters.title'),
        patients: t('medicalofficesearch.filters.patients'),
        name: t('medicalofficesearch.filters.name')[labelSuffix.value],
      },
    },
    type: t('medicalofficesearch.type'),
    states: t('medicalofficesearch.states'),
    toggle: {
      map: t('medicalofficesearch.toggle.card'),
      list: t('medicalofficesearch.toggle.list'),
    },
    messages: {
      emptyResults: {
        text: t('medicalofficesearch.messages.emptyResults.text')[labelSuffix.value],
        title: t('medicalofficesearch.messages.emptyResults.title'),
        link: t('medicalofficesearch.messages.emptyResults.link'),
      },
      noResults: t('medicalofficesearch.messages.noResults')[labelSuffix.value],
      moreResults: t('medicalofficesearch.messages.moreResults'),
      confirm: {
        office: {
          ...t('medicalofficesearch.messages.office'),
          title: t('medicalofficesearch.messages.office.title')[labelSuffix.value],
          description: t('medicalofficesearch.messages.office.description')[labelSuffix.value],
        },
        product: t('medicalofficesearch.messages.product'),
      },
    },
    reductions: {
      reduction: t('product.reduction'),
      title: t('medicalofficesearch.reductions.title'),
      text: t('medicalofficesearch.reductions.text'),
      note: t('medicalofficesearch.reductions.note'),
      level: t('medicalofficesearch.reductions.level'),
    },
  }
})

const searchOptions = computed(() => {
  return {
    filterableProducts: false,
    location: contractData.value.address,
  }
})

// METHODS
function openModal() {
  modalRef.value?.open()
}

function closeModal() {
  modalRef.value?.close()
}

function handleBeforeOpen(event) {
  const { getProduct } = usePersonSpecificProduct()
  const { setOptions } = useMedicalOffice()

  const { personId, product, source } = event

  selectedPersonId.value = personId
  selectedProduct.value = product
  selectedSource.value = source

  setOptions({
    labels: labels.value,
    callbacks: {
      getProduct: productId => getProduct(personId, productId),
      getLocations: payload => masterDataAPI.getLocations(payload),
      findProviders: payload => masterDataAPI.findProviders(payload),
    },
  })
}

/**
 * update ui-doctor and add product to basket
 * @param  {Object} data
 */
function updateDoctor(data) {
  const doctor = transformDoctorForProduct(data)

  closingCallback.value(doctor)

  closeModal()
}

// WATCHERS

watch(
  () => initialZoom.value,
  value => {
    if (initialZoom.value !== value) {
      onZoom(value)
    }
  }
)

// LIFECYCLE HOOKS
onBeforeMount(() => {
  events$.on(EVENT_MEDICAL_OFFICE_MODAL_OPEN, event => {
    handleBeforeOpen(event)
    openModal()
    if (event.callback) closingCallback.value = event.callback
    if (event.isProductSwapDisallowed) isProductSwapDisallowed.value = event.isProductSwapDisallowed
  })
})
onBeforeUnmount(() => {
  events$.off(EVENT_MEDICAL_OFFICE_MODAL_OPEN, openModal)
})
</script>

<style>
:root #medical-office-search-modal {
  &.dvp-modal-container {
    z-index: var(--dvp-stack-level-modal-stacked);
  }

  .dvp-modal {
    position: relative;
  }

  .dvp-modal:not(:has(.mos-maps--list-only)) .dvp-modal__top {
    cursor: pointer;
    display: flex;
    padding: 0;
    background-color: inherit;
    border: none;
    border-radius: var(--fixed-spacing-fix-02);
    position: absolute;
    right: var(--dotcom-responsive-gutter-responsive);
    top: var(--dotcom-responsive-gutter-responsive);
    z-index: var(--dvp-stack-level-element);
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
  }

  .dvp-modal:not(:has(.mos-maps--list-only)) .close-button {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      vertical-align: text-top;
    }
  }

  .dvp-modal:not(:has(.mos-maps--list-only)) .scrollable .the-grid {
    width: 100%;
    max-width: 100%;
  }
}

@media (--v-large) {
  :root #medical-office-search-modal {
    .dvp-modal:not(:has(.mos-maps--list-only)) .dvp-modal__top {
      height: 50px;
      width: 50px;
    }
  }
}
</style>
