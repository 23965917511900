<template>
  <div class="entry">
    <div
      class="title-3"
      v-text="$props.titleLabel"
    />

    <div
      class="body-3"
      v-text="$props.description"
    />

    <basic-link
      v-for="link in $props.links"
      :key="link.name"
      data-track-as="fab-appointment"
      :disabled="link.disabled"
      :data-track-text="$t(link.name)"
      :href="link.url ? $t(link.url) : null"
      :label="$t(link.name)"
      :target="link.url ? link.target : null"
      @click="handleLinkClick(link)"
    >
      <template #leading>
        <the-icon
          art="solid"
          :name="`${link.icon || 'arrow-right'}`"
        />
      </template>
    </basic-link>
  </div>
</template>

<script setup>
import { BasicLink } from '@/components/Basic'

// INIT
const emit = defineEmits(['action'])
defineProps({
  description: {
    type: String,
    required: true,
  },
  links: {
    type: Array,
    default: () => [],
  },
  titleLabel: {
    type: String,
    required: true,
  },
})

// METHODS
function handleLinkClick(link) {
  emit('action', link.action)
}
</script>

<style scoped>
.entry {
  display: flex;
  flex-direction: column;
  row-gap: var(--fixed-spacing-fix-02);
}
</style>
