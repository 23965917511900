import { debounce } from 'lodash'

/**
 * @note: the submit event from personData and adminData has to be catched differently:
 * the component emits an @update, when submit has been triggered manually (by method-call)
 */
class FormTracker {
  constructor(el, binding, vnode) {
    this.el = el
    this.vnode = vnode

    this.triggerCancel = binding.value.triggerCancel
    this.triggerInteraction = binding.value.triggerInteraction
    this.controller = new AbortController()

    window.setTimeout(() => this.registerListener(), 500)
  }

  handleInteraction(event) {
    debounce(() => {
      this.triggerInteraction(event.target.name)
      this.controller.abort()
    }, 50)()
  }

  registerListener() {
    this.elements = Array.from(this.el.querySelectorAll('input, textarea, select'))
    const cancelButtons = Array.from(this.el.querySelectorAll('button[type=cancel]'))

    cancelButtons.forEach(button => {
      button?.addEventListener('click', event => {
        event.preventDefault()
        this.triggerCancel()
      })
    })

    this.elements.forEach(element => {
      const isRadio = element.getAttribute('type')?.toLowerCase() === 'radio'
      const isCheckbox = element.getAttribute('type')?.toLowerCase() === 'checkbox'

      if (!isRadio && !isCheckbox) {
        element.addEventListener('focus', event => this.handleInteraction(event), { signal: this.controller.signal })
      }

      element.addEventListener('change', event => this.handleInteraction(event), { signal: this.controller.signal })
    })
  }
}

export default {
  beforeMount: function (el, binding, vnode) {
    new FormTracker(el, binding, vnode)
  },
}
