import { ROUTE_INDEX, ROUTE_NAME } from '@/config/constants'

import { getNextRoute } from '@/helpers/Route'

import basketStore from '@/store/basket'
import partnerStore from '@/store/partner'
import productStructureStore from '@/store/productStructure'
import useStatus from '@/hooks/useStatus'

// HOOKS
const { offerValid } = useStatus()

function reroute(from, next) {
  if (from.name) {
    next({
      name: from.name,
      params: from.params,
    })
  } else {
    next({
      name: getNextRoute(basketStore.basket),
      replace: true,
    })
  }
}

export default [
  {
    beforeEnter: (_to, from, next) => {
      // Trying to access url without a basket at all
      if (basketStore.isTemporaryBasket()) return reroute(from, next)

      const productStructurePromise = productStructureStore.ready()
      const partnerPromise = partnerStore.ready()
      Promise.all([productStructurePromise, partnerPromise]).then(() => {
        if (
          !offerValid(basketStore.basket, basketStore.basket.persons) ||
          basketStore.basket.advisoryProtocol !== true
        ) {
          return reroute(from, next)
        } else {
          next()
        }
      })
    },
    component: () => import('@/modules/Configurator/screens/ScreenAdvisoryProtocol'),
    meta: {
      headerDisabled: true,
      index: ROUTE_INDEX.ADVISORY_PROTOCOL,
      navigation: false,
      secured: true,
    },
    name: ROUTE_NAME.ADVISORY_PROTOCOL,
    path: '/advisory-protocol',
  },
]
