<template>
  <component :is="isSMViewport ? 'div' : BasicScrollingWithGradient">
    <div
      ref="resultsListRef"
      class="results-list"
    >
      <results-card
        v-for="(office, key) in $props.results"
        :key="key"
        :active="isActive(office)"
        :auto-scroll="$props.autoScroll"
        :hover="isHover(office)"
        :office="office"
        :product="$props.product"
        @submit="$emit('submit')"
      />
      <template v-if="$props.results.length === 0">
        <basic-message
          class="results-list__message"
          severity="warning"
          :closable="false"
          :content="getTranslation('messages.emptyResults.text')"
          :title-label="getTranslation('messages.emptyResults.title')"
        />
      </template>
    </div>
  </component>
</template>

<script setup>
import { ref } from 'vue'

import { BasicMessage, BasicScrollingWithGradient } from '@/components/Basic'

import useBrowser from '@/hooks/useBrowser'
import useMedicalOffice from '@/hooks/useMedicalOffice'
import useMapSearch from '@/hooks/useMapSearch'

import ResultsCard from '@/components/MedicalSearch/components/ResultsCard'

// HOOKS
const { isSMViewport } = useBrowser()
const { getTranslation } = useMedicalOffice()
const { hoveredId, selectedId } = useMapSearch()

// INIT
defineEmits(['close', 'submit'])
defineProps({
  autoScroll: {
    type: Boolean,
    default: false,
  },
  product: {
    type: Object,
    default: () => undefined,
  },
  results: {
    type: Array,
    default: () => [],
  },
})

// DATA
const resultsListRef = ref(null)

// METHODS
function isActive(office) {
  return office.zsrNumber === selectedId.value
}

function isHover(office) {
  return office.zsrNumber === hoveredId.value
}
</script>

<style scoped>
.results-list {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;

  &__head {
    position: absolute;
    top: -90px;
    right: 0;
    width: 100%;
    height: 90px;
    max-width: 160px;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;

    &--sticky {
      z-index: var(--dvp-stack-level-element);
      position: fixed;
      top: 0;
      left: 0;
      max-width: 433px;
      background-color: var(--c-primary-neutral-3);
    }
  }

  &__title {
    position: relative;
    font-size: 12px;
    line-height: 20px;
    width: 50px;

    div {
      position: absolute;
      bottom: -20px;
      right: -22px;
      transform: rotate(-80deg);
      display: inline-block;
      height: 100%;
      width: 100%;
    }
  }

  &__message {
    margin-top: var(--fixed-spacing-fix-04);

    &-empty {
      display: flex;
      flex-direction: column;
      font-weight: bold;

      &__link {
        padding-top: 5px;
        align-self: flex-end;
      }
    }
  }
}

:root .scrollable {
  scrollbar-width: 8px;
}
</style>
