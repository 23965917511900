import { getNextRoute } from '@/helpers/Route'

import basketStore from '@/store/basket'
import useStatus from '@/hooks/useStatus'

import { ROUTE_INDEX, ROUTE_NAME } from '@/config/constants'

// HOOKS
const { isPersonalDataValid } = useStatus()

export default [
  {
    beforeEnter: function (_to, _from, next) {
      const personsValid = isPersonalDataValid(basketStore.basket.persons)

      if (!personsValid) {
        next({ name: getNextRoute(basketStore.basket) })
      } else {
        next()
      }
    },
    component: () => import('@/modules/Configurator/screens/ScreenBasket'),
    meta: {
      index: ROUTE_INDEX.BASKET,
      secured: true,
    },
    name: ROUTE_NAME.BASKET,
    path: '/basket/:personId?/:prop?/:productId?/',
    props: route => ({
      personId: route.params.personId,
      prop: route.params.prop,
      productId: route.params.productId,
    }),
  },
]
